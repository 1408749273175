import React, { useEffect, useState } from 'react';
import GighubInvoice from './InvoicePreviewTemplate';
import axios from 'axios';

const InvoiceEditDetails = () => {
  // State variables
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [editedInvoice, setEditedInvoice] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [salesDetails, setSalesDetails] = useState(null);

  // Fetch data on component mount
  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const response = await fetch('https://demo.claytelecom.com/api/Authentication/Get_invoice_details');
        const data = await response.json();

        const jsonDataArray = JSON.parse(data.jsondata);
        setInvoices(jsonDataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchSalesData = async () => {
      try {
        const response = await fetch('https://demo.claytelecom.com/api/Authentication/Get_sales_details?flag=1');
        const data = await response.json();
        if (data.isSuccess) {
          const salesData = JSON.parse(data.jsondata)[0];
          setSalesDetails(salesData);
        } else {
          console.error('API request for sales details failed:', data.message);
        }
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };

    fetchInvoiceData();
    fetchSalesData();
  }, []);

  // Handle edit button click
  const handleEditClick = (invoice) => {
    setEditedInvoice({ ...invoice });
    setSelectedInvoice(invoice);
  };

  // Handle form submission
const handleFormClose = async () => {
  try {
    // Update sales details
    const updateResponse = await axios.post(
      'https://demo.claytelecom.com/api/Authentication/update_sales_details',
      {
        flag: 1,
        id: editedInvoice.Id,
        customer_name: editedInvoice.customer_name,
        invoiceno: editedInvoice.invoiceno,
        amount: editedInvoice.amount,
        status: 1,
      }
    );

    console.log('Update response:', updateResponse.data);

    // Fetch updated invoice details
    const updatedData = await fetch('https://demo.claytelecom.com/api/Authentication/Get_invoice_details');
    const updatedJsonData = await updatedData.json();
    const updatedJsonDataArray = JSON.parse(updatedJsonData.jsondata);

    setInvoices(updatedJsonDataArray);
    setValidationErrors(null);
  } catch (error) {
    console.error('Error updating sales details:', error);
    if (error.response) {
      console.log('Server response:', error.response.data);
      if (error.response.data.errors) {
        setValidationErrors(error.response.data.errors);
      }
    }
  } finally {
    // Clear both editedInvoice and selectedInvoice states
    setSelectedInvoice(null); // Clear selectedInvoice state
    setEditedInvoice(null);
  }
};


  // Handle input change in the form
  const handleInputChange = (e) => {
    setEditedInvoice({ ...editedInvoice, [e.target.name]: e.target.value });
  };

  // Table columns definition
  const columns = [
    { key: 'Id', header: 'ID', className: 'py-2' },
    { key: 'clientname', header: 'Customer Name', className: 'py-2' },
    { key: 'invoice_name', header: 'Invoice Name', className: 'py-2' },
    { key: 'brand_name', header: 'Brand Name', className: 'py-2' },
    { key: 'qty', header: 'Qty', className: 'py-2' },
    { key: 'amount', header: 'Amount', className: 'py-2' },
  ];

  // Render table header
  const renderTableHeader = () => (
    <tr>
      {columns.map((column) => (
        <th key={column.key} className={column.className}>
          {column.header}
        </th>
      ))}
      <th>Action</th>
    </tr>
  );

  // Render table data
  const renderTableData = () => (
    invoices.map((invoice) => (
      <tr key={invoice.Id}>
        {columns.map((column) => (
          <td key={column.key} className={column.className}>
            {invoice[column.key]}
          </td>
        ))}
        <td>
          <button onClick={() => handlePreviewClick(invoice)}>View</button>
          <button onClick={() => handleSendClick(invoice)}>Send</button>
          <button onClick={() => handleEditClick(invoice)}>Edit</button>
        </td>
      </tr>
    ))
  );

  // Handle preview button click
  const handlePreviewClick = async (invoice) => {
    try {
      // Fetch additional data for the selected invoice
      const brandId = invoice.Brand_id;
      const responseBrand = await fetch(`https://demo.claytelecom.com/api/Authentication/Get_bank_byid?brandid=${brandId}`);
      const dataBrand = await responseBrand.json();

      const jsonDataArrayBrand = JSON.parse(dataBrand.jsondata);
      const selectedBank = jsonDataArrayBrand[0];

      const clientId = invoice.client_id;
      const responseClient = await fetch('https://demo.claytelecom.com/api/Authentication/Get_clientdetails?flag=1');
      const dataClient = await responseClient.json();

      const jsonDataArrayClient = JSON.parse(dataClient.jsondata);
      const selectedClient = jsonDataArrayClient.find(client => client.c_id === clientId);

      const bankId = invoice.brand_id;
      const responsebank = await fetch('https://demo.claytelecom.com/api/Authentication/Get_brand');
      const databank = await responsebank.json();

      const jsonDataArraybank = JSON.parse(databank.jsondata);
      const selectedbank = jsonDataArraybank.find(bank => bank.bank_id === bankId);

      if (selectedClient) {
        // Set selectedInvoice with additional data
        setSelectedInvoice({
          ...invoice,
          selectedBank,
          selectedClient,
          selectedbank,
        });
      } else {
        console.error(`Client details not found for client_id: ${clientId}`);
        console.error(`Bank details not found for bank_id: ${bankId}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Handle send button click
  const handleSendClick = async (invoice) => {
    try {
      // Send the invoice
      const invoiceId = invoice.Id;
      const sendResponse = await fetch(`https://demo.claytelecom.com/api/Authentication/mailing_invoice?invoiceid=${invoiceId}`);

      if (sendResponse.ok) {
        console.log('Invoice sent successfully:', sendResponse);
        alert('Invoice has been sent successfully!');
      } else {
        console.error('Error sending invoice:', sendResponse.status);
        alert('Error sending invoice. Please try again.');
      }
    } catch (error) {
      console.error('Error sending invoice:', error);
      alert('Error sending invoice. Please try again.');
    }
  };

  // Render preview modal
  const renderPreviewModal = () => {
    if (!selectedInvoice) {
      return null;
    }

    const modalStyle = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      width: '100%', // Adjust the width as needed
      maxHeight: '100vh', // Adjust the maxHeight as needed
      overflowY: 'auto', // Enable vertical scrolling if necessary
    };

    const handlePrint = () => {
      window.print();
    };

    return (
      <div style={modalStyle}>
        <button onClick={() => setSelectedInvoice(null)}>Close</button>

        {/* Assuming GighubInvoice is the component to render invoice details */}
        <GighubInvoice selectedInvoice={selectedInvoice} />

        <button onClick={handlePrint}>Print</button>
      </div>
    );
  };

  return (
    <div>
      <h2>Invoice Details</h2>
      <table>
        <thead>{renderTableHeader()}</thead>
        <tbody>{renderTableData()}</tbody>
      </table>

      {/* Corrected rendering with parentheses */}
      {selectedInvoice && (
        <div>
          <h1 className="text-3xl font-bold mb-4">Invoice Details</h1>
          <form>
            {/* ... (form input fields) */}

            <label>Customer Name:</label>
            <input type="text" name="customer_name" value={editedInvoice.customer_name} onChange={handleInputChange} />

            <label>Order Number:</label>
            <input type="text" name="order_no" value={editedInvoice.order_no} onChange={handleInputChange} />

            <label>Amount:</label>
            <input type="text" name="amount" value={editedInvoice.amount} onChange={handleInputChange} />

            {validationErrors && (
              <div style={{ color: 'red' }}>
                {Object.entries(validationErrors).map(([key, value]) => (
                  <p key={key}>{`${key}: ${value}`}</p>
                ))}
              </div>
            )}
            <div className="w-full mb-4 flex justify-center">
              <button
                style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
                className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleFormClose}
              >
                Save Changes
              </button>
              <button
                type="button"
                style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
                className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setSelectedInvoice(null)}
              >
                Invoicedetails
              </button>
            </div>
          </form>
        </div>
      )}

    </div>
  );
};

export default InvoiceEditDetails;
