import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Currencydetails = () => {
  const [currencyData, setCurrencyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [editCurrencyName, setEditCurrencyName] = useState('');
  const [editConversion, setEditConversion] = useState('');
  const itemsPerPage = 5;

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    // Get the token from local storage
    const token = localStorage.getItem('token');

    // Check if the token is available
    if (!token) {
      console.error('Token not found.');
      alert('You are not authorized to view this content. Please log in.');
      return;
    }

    try {
      const response = await fetch(`https://demo.claytelecom.com/api/Authentication/Get_Currencydetails?flag=1`, {
        headers: {
          Authorization: `Bearer ${token}`, // Attach the token to the request
        },
      });

      const responseData = await response.json();

      // Check if jsondata is present and parse it into an array
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      // Check if data is an array before setting it
      if (Array.isArray(data)) {
        setCurrencyData(data);
      } else {
        console.error('Parsed data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = currencyData.slice(indexOfFirstItem, indexOfLastItem);

  const renderTableRows = () => {
    return currentItems.map((currency, index) => (
      <tr key={index} className="border-b">
        <td className="py-2">{currency.Id}</td>
        <td className="py-2">{currency.curency_name}</td>
        <td className="py-2">{currency.conversion}</td>
        <td className="py-2">{currency.created_date}</td>
        <td className="py-2">{currency.updated_date}</td>
        <td className="py-2"><button onClick={() => handleEdit(currency)} type="button"
         style={{ borderRadius: '20px',  alignItems: 'center',justifyContent:'center' }}        
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Edit
      </button>
        </td>
      </tr>
    ));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleEdit = (currency) => {
    // Set the selected currency for editing
    setSelectedCurrency(currency);
    // Set the initial values in the edit form
    setEditCurrencyName(currency.curency_name);
    setEditConversion(currency.conversion);
  };

  const handleUpdate = async () => {
    if (!selectedCurrency) {
      console.error('No currency selected for update.');
      return;
    }

    // Prepare data for update
    const updateData = {
      flag: 1,
      currency_name: editCurrencyName,
      conversion: editConversion,
      id: selectedCurrency.Id,
    };

    try {
      // Make the update request
      const response = await axios.post('https://demo.claytelecom.com/api/Authentication/update_currency', updateData);

      if (response.status === 200) {
        console.log('Currency updated successfully:', response.data);
        alert('Currency updated successfully!');
        // Refresh the data after successful update
        fetchData();
        // Clear the selected currency and edit values
        setSelectedCurrency(null);
        setEditCurrencyName('');
        setEditConversion('');
      } else {
        console.error('Failed to update currency:', response.data);
        alert('Failed to update currency. Please check your input and try again.');
      }
    } catch (error) {
      console.error('Error updating currency:', error);
      alert('An error occurred while updating currency. Please try again later.');
    }
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
      <h1 className="text-3xl font-bold mb-4"> Currency Details</h1>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="py-2">ID</th>
            <th className="py-2">curency_name</th>
            <th className="py-2">conversion </th>
            <th className="py-2">created_date </th>
            <th className="py-2">updated_date</th>
            <th className="py-2">Action</th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>
<br/>
<div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
  <h1 className="text-3xl font-bold mb-4">Edit Currency</h1>

  {selectedCurrency && (
    <form className="max-w-md mx-auto">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Currency Name:</label>
        <input
          type="text"
          className="w-full border border-gray-300 p-2 rounded"
          value={editCurrencyName}
          onChange={(e) => setEditCurrencyName(e.target.value)}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Conversion:</label>
        <input
          type="text"
          className="w-full border border-gray-300 p-2 rounded"
          value={editConversion}
          onChange={(e) => setEditConversion(e.target.value)}
        />
      </div>

      <button
        type="button" // Change to "submit" if you want to submit the form
        onClick={handleUpdate}
             style={{ borderRadius: '20px', padding: '10px', alignItems: 'center',justifyContent:'center' }} // Added styles
       
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Update Currency
      </button>
    </form>
  )}
</div>


      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Prev
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={indexOfLastItem >= currencyData.length}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Currencydetails;
