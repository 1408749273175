import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CreateClientForm = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage

  const [vendorNames, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [clientFormData, setClientFormData] = useState({
    flag: 1,
    userName: '',
    lastname: '',
    email: '',
    mobileno: '',
    phoneno: '',
    website: '',
    companyname: '',
    contactno: '',
    zip_code: '',
    fax: '',
    vid: '',
    address: '',
  });

  const handleNavigateToDetails = () => {
    navigate('/Clientdetails');
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  // Fetch vendors from API
  const fetchVendors = async () => {
    try {
      const response = await fetch(`${apiUrl}Getvendordetail?flag=1`, {
        headers: {
          'Authorization': `Bearer ${token}`, // Add token to request header
          'Content-Type': 'application/json', // Set content type
        },
      });

      const responseData = await response.json();
      // Check if jsondata is present and parse it into an array
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      // Check if data is an array before setting it
      if (Array.isArray(data)) {
        setVendors(data.map((vendor) => ({ value: vendor.v_id, label: vendor.name })));
      } else {
        console.error('Parsed data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleVendorChange = (e) => {
    const selectedVendorId = e.target.value;
    setSelectedVendor(selectedVendorId);

    // Set the selected v_id in clientFormData
    setClientFormData((prevFormData) => ({
      ...prevFormData,
      vid: selectedVendorId,
    }));
  };

  const handleClientChange = (e) => {
    const { name, value } = e.target;
    setClientFormData({
      ...clientFormData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}CreateClient`, { // Use apiUrl here
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Add token to request header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(clientFormData),
      });

      const responseData = await response.json();

      if (response.ok) {
        alert('Client created successfully. Thank you!');
        setClientFormData({
          flag: 1,
          userName: '',
          lastname: '',
          email: '',
          mobileno: '',
          phone_no: '',
          website: '',
          companyname: '',
          contactno: '',
          zip_code: '',
          fax: '',
          vid: '',
          address: '',
        });
        navigate('/ClientDetails'); // Update the route accordingly
      } else {
        alert('Failed to create client. Please check your input and try again.');
        console.error('Failed to create client:', responseData);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg ">
      <h1 className="text-center text-3xl font-bold mb-6">Client Add</h1>

      {/* Dropdown for selecting a vendor */}
      <label className="block mb-4">
        Select Vendor:
        <select
          value={selectedVendor}
          onChange={handleVendorChange}
          className="block w-full border border-gray-300 p-2 rounded"
          required  // Make the dropdown required

        >
          <option value="0" >Select a vendor</option>
          {vendorNames.map((vendor) => (
            <option key={vendor.value} value={vendor.value}>
              {vendor.label}
            </option>
          ))}
        </select>
      </label>
      <div className="flex flex-wrap -mx-2">

      {/* Form for creating a client */}
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
          First Name:
          <input
            type="text"
            name="userName"
            value={clientFormData.userName}
            onChange={handleClientChange}
              className="block w-full mt-1 p-2 border rounded-md"
              required 
         />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">

          <label className="block mb-2 text-gray-700">
          Last Name:
          <input
            type="text"
            name="lastname"
            value={clientFormData.lastname}
            onChange={handleClientChange}
            className="block w-full border border-gray-300 p-2 rounded"
            required 
          />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">

<label className="block mb-2 text-gray-700">
Address
<input
  type="text"
  name="address"
  value={clientFormData.address}
  onChange={handleClientChange}
  className="block w-full border border-gray-300 p-2 rounded"
  required 
/>
</label>
</div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
          Email:
          <input
            type="email"
            name="email"
            value={clientFormData.email}
            onChange={handleClientChange}
            className="block w-full border border-gray-300 p-2 rounded"
            required 
         />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
          Website:
          <input
            type="text"
            name="website"
            value={clientFormData.website}
            onChange={handleClientChange}
            className="block w-full border border-gray-300 p-2 rounded"
            required 
          />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
          Mobile Number:
          <input
            type="number"
            name="mobileno"
            value={clientFormData.mobileno}
            onChange={handleClientChange}
            className="block w-full border border-gray-300 p-2 rounded"
            required 
          />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700"> 
          Phoneno:
          <input
            type="text"
            name="phone_no"
            value={clientFormData.phone_no}
            onChange={handleClientChange}
            className="block w-full border border-gray-300 p-2 rounded"
          />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">  
            Company Name:
          <input
            type="text"
            name="companyname"
            value={clientFormData.companyname}
            onChange={handleClientChange}
            className="block w-full border border-gray-300 p-2 rounded"
            required 
         />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">  
            Country
            <input
            type="text"
            name="contactno"
            value={clientFormData.contactno}
            onChange={handleClientChange}
            className="block w-full border border-gray-300 p-2 rounded"
          />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">  
          Zip Code:
          <input
            type="number"
            name="zip_code"
            value={clientFormData.zip_code}
            onChange={handleClientChange}
            className="block w-full border border-gray-300 p-2 rounded"
          />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">  
          Fax:
          <input
            type="number"
            name="fax"
            value={clientFormData.fax}
            onChange={handleClientChange}
            className="block w-full border border-gray-300 p-2 rounded"
          />
        </label>
      </div>
      <div className="w-full mb-4 flex justify-center">

      <button
            style={{ borderRadius: '20px', padding: '10px', alignItems: 'center',justifyContent:'center' }} // Added styles
            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="submit"
      >
        Submit
      </button>
      <button
            type="button"
            onClick={handleNavigateToDetails}
            style={{ borderRadius: '20px', padding: '10px', alignItems: 'center',justifyContent:'center' }} // Added styles
            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
            Client Details
          </button>
      </div>
      </div>
    </form>
  );
};

export default CreateClientForm;
