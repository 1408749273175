import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CurrencyUpdate = () => {
  const [vendorNames, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [currencyName, setCurrencyName] = useState('');
  const [conversion, setConversion] = useState('');
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable for the base URL

  // Retrieve the token from localStorage
  const token = localStorage.getItem('token');

  useEffect(() => {
    // Check if token is present
    if (token) {
      fetchVendors();
    } else {
      alert('Token is missing. Please log in again.');
      // Optionally redirect to login page
      // window.location.href = '/login';
    }
  }, [token]);

  // Fetch vendors from API
  const fetchVendors = async () => {
    try {
      const response = await axios.get(`${apiUrl}Get_Currencydetails?flag=1`, {
        headers: {
          Authorization: `Bearer ${token}` // Attach the token to the headers
        }
      });

      const responseData = response.data;

      // Check if jsondata is present and parse it into an array
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      // Check if data is an array before setting it
      if (Array.isArray(data)) {
        setVendors(data.map((vendor) => ({ value: vendor.Id, label: vendor.curency_name })));
      } else {
        console.error('Parsed data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleVendorChange = (e) => {
    const selectedVendorId = e.target.value;
    setSelectedVendor(selectedVendorId);
  };

  const handleUpdate = async () => {
    if (!selectedVendor || !currencyName || !conversion) {
      alert('Please fill in all fields before updating.');
      return;
    }

    // Prepare data for update
    const updateData = {
      flag: 1,
      currency_name: currencyName,
      conversion: conversion,
      id: selectedVendor // Assuming selectedVendor is the correct ID field
    };

    try {
      // Make the update request
      const response = await axios.post(`${apiUrl}/update_currency`, updateData, {
        headers: {
          Authorization: `Bearer ${token}` // Attach the token to the headers
        }
      });

      if (response.status === 200) {
        console.log('Currency updated successfully:', response.data);
        alert('Currency updated successfully!');
        // You can use window.location.reload() to refresh the page
        window.location.reload();
      } else {
        console.error('Failed to update currency:', response.data);
        alert('Failed to update currency. Please check your input and try again.');
      }
    } catch (error) {
      console.error('Error updating currency:', error);
      alert('An error occurred while updating currency. Please try again later.');
    }
  };

  
  return (
    <div>
      
      <label className="block mb-4">
        Select Currency:
        <select
          value={selectedVendor}
          onChange={handleVendorChange}
          className="block w-full border border-gray-300 p-2 rounded"
          required  // Make the dropdown required
        >
          <option value="" disabled>Select a Currency</option>
          {vendorNames.map((vendor) => (
            <option key={vendor.value} value={vendor.value}>
              {vendor.label}
            </option>
          ))}
        </select>
      </label>

      <div>
        <label>Currency Name:</label>
        <input type="text" value={currencyName} onChange={(e) => setCurrencyName(e.target.value)} />
      </div>

      <div>
        <label>Conversion:</label>
        <input type="text" value={conversion} onChange={(e) => setConversion(e.target.value)} />
      </div>

      <button onClick={handleUpdate}>Update Currency</button>
    </div>
  );
};

export default CurrencyUpdate;
