import React, { useEffect, useState } from 'react';
import GighubInvoice from './InvoicePreviewTemplate';
import ClayInvoice from './PreviewClay';
import AirhubInvoice from './PreviewAirhub';
import PentagramInvoice from './PreviewPentagram';
import ClayLLPLTD from './Preview';
import * as XLSX from 'xlsx';

const ComponentMap = {
   4: ClayInvoice,
  2: GighubInvoice,
  3: AirhubInvoice,
  21:ClayLLPLTD,
  1: PentagramInvoice,
};

const Invoicedetails = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}Get_invoice_details`);
        const data = await response.json();

        // Parse the 'jsondata' string into an array
        const jsonDataArray = JSON.parse(data.jsondata);

        setInvoiceData(jsonDataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

 const columns = [
    { key: 'Id', header: 'ID', className: 'py-2' },
    { key: 'brand_name', header: 'Brand Name', className: 'py-1' },
    { key: 'clientname', header: 'Customer Name', className: 'py-2' },

    { key: 'item', header: 'Item', className: 'py-2' },
    { key: 'Description', header: 'Description', className: 'py-2' },
    { key: 'order_no', header: 'Order No', className: 'py-2' },
    { key: 'notes', header: 'Notes', className: 'py-2' },

    { key: 'qty', header: 'Qty', className: 'py-2' },
    { key: 'amount', header: 'Amount', className: 'py-2' },
    { key: 'Unit_price', header: 'Unit price', className: 'py-2' },
    { key: 'financeyear', header: 'Financeyear', className: 'py-2' },

    { key: 'updated_date', header: 'Updated  of Invoice', className: 'py-2' },
    { key: 'invoiceno', header: 'Invoice No', className: 'py-2' },
    { key: 'invoice_date', header: 'Date of Invoice', className: 'py-2' },
    { key: 'billing_date', header: 'Billing of Invoice', className: 'py-2' },
    { key: 'Due_date', header: 'Due Date Number', className: 'py-2' },
    { key: 'created_date', header: 'Created  of Invoice', className: 'py-2' },

    { key: 'invoice_name', header: 'Invoice Number', className: 'py-2' },
    { key: 'bank_fee', header: 'Bank Fee', className: 'py-2' },
    { key: 'discount', header: 'Discount ', className: 'py-2' },
    { key: 'Brand_id', header: 'Brand ', className: 'py-2' },

  ];

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(invoiceData); // Use currentItems instead of Clientdetails
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Invoicedetails');
    XLSX.writeFile(workbook, 'Invoicedetails.xlsx');
  };
  

  const renderTableHeader = () => {
    return (
      <tr>
        {columns.map((column) => (
          <th key={column.key} className={column.className}>
            {column.header}
          </th>
        ))}
        <th>Action</th>
      </tr>
    );
  };

  const renderTableData = () => {
    return invoiceData.map((invoice) => (
      <tr key={invoice.Id}>
        {columns.map((column) => (
          <td key={column.key} className={column.className}>
            {invoice[column.key]}
          </td>
        ))}
        <td>
          <button onClick={() => handlePreviewClick(invoice)}>View</button>
          <button onClick={() => handleSendClick(invoice)}>Send</button>
        </td>
      </tr>
    ));
  };

  const handlePreviewClick = async (invoice) => {
    try {
      const brandId = invoice.Brand_id;
      const responseBrand = await fetch(`${apiUrl}Get_bank_byid?brandid=${brandId}`);
      const dataBrand = await responseBrand.json();

      const jsonDataArrayBrand = JSON.parse(dataBrand.jsondata);
      const selectedBank = jsonDataArrayBrand[0];

      const clientId = invoice.client_id;
      const responseClient = await fetch(`${apiUrl}Get_clientdetails?flag=1`);
      const dataClient = await responseClient.json();

      const jsonDataArrayClient = JSON.parse(dataClient.jsondata);
      const selectedClient = jsonDataArrayClient.find((client) => client.c_id === clientId);

      const bankId = invoice.brand_id;
      const responsebank = await fetch(`${apiUrl}Get_brand`);
      const databank = await responsebank.json();

      const jsonDataArraybank = JSON.parse(databank.jsondata);
      const selectedbank = jsonDataArraybank.find((bank) => bank.bank_id === bankId);
      if (selectedClient) {
        setSelectedInvoice({
          ...invoice,
          selectedBank,
          selectedClient,
          selectedbank,
        });
      } else {
        console.error(`Client details not found for client_id: ${clientId}`);
        console.error(`Bank details not found for bank_id: ${bankId}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSendClick = async (invoice) => {
    try {
      const invoiceId = invoice.Id;
      const apiUrl = 'https://demo.claytelecom.com/api/Authentication/mailing_invoice';
      const response = await fetch(`${apiUrl}?invoiceid=${invoiceId}`);
      
      if (response.ok) {
        // Email sent successfully
        alert('Invoice sent successfully via email.');
      } else {
        // Error handling if email sending fails
        throw new Error('Failed to send invoice via email.');
      }
    } catch (error) {
      console.error('Error sending invoice:', error);
      alert('Error sending invoice. Please try again.');
    }
  };
  
  const renderPreviewModal = () => {
    if (!selectedInvoice) {
      return null;
    }

    const SelectedComponent = ComponentMap[selectedInvoice.Brand_id];

    if (!SelectedComponent) {
      console.error(`Component not found for Brand_id: ${selectedInvoice.Brand_id}`);
      return null;
    }
    const modalStyle = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      width: '100%', // Adjust the width as needed
      maxHeight: '100vh', // Adjust the maxHeight as needed
      overflowY: 'auto', // Enable vertical scrolling if necessary
    };

    const handlePrint = () => {
      window.print();
    };

    return (
      <div style={modalStyle}>
        <button onClick={() => setSelectedInvoice(null)}>Close</button>
        <SelectedComponent selectedInvoice={selectedInvoice} />
        <button onClick={handlePrint}>Print</button>
      </div>
    );
  };

  return (
    <div style={{ maxWidth: '1300px', overflowX: 'auto' }}>
      <h2>Invoice Details PP</h2>
      <button onClick={downloadExcel}>Download Excel</button>

      <table>
        <thead>{renderTableHeader()}</thead>
        <tbody>{renderTableData()}</tbody>
      </table>
      {renderPreviewModal()}
    </div>
  );
};

export default Invoicedetails;
