import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AssignInventory() {
  const [clientList, setClientList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [selectedVendorId, setSelectedVendorId] = useState('');
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [quantityRequired, setQuantityRequired] = useState(0);
  const [totalStock, setTotalStock] = useState(0);
  const [stockLeft, setStockLeft] = useState(0);
  const navigate = useNavigate();

  // Retrieve the token from localStorage
  const token = localStorage.getItem('token');

  // Assign Details navigation
  const AssignDetail = () => {
    navigate('/AssignDetails');
  };

  useEffect(() => {
    // Define headers with the token
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include the token here
    };

    // Fetch client details
    fetch("https://demo.claytelecom.com/api/Authentication/Get_clientdetails?flag=1", { headers })
      .then(response => response.json())
      .then(data => {
        const parsedData = JSON.parse(data.jsondata);

        // Extracting names and IDs from the fetched data
        const clients = parsedData.map(client => ({
          id: client.c_id,
          name: `${client.name}`
        }));
        setClientList(clients);
      })
      .catch(error => console.error('Error fetching client details:', error));

    // Fetch vendor details
    fetch("https://demo.claytelecom.com/api/Authentication/Getvendordetail?flag=1", { headers })
      .then(response => response.json())
      .then(data => {
        const parsedData = JSON.parse(data.jsondata);

        // Extracting names and IDs from the fetched data
        const vendors = parsedData.map(vendor => ({
          id: vendor.v_id,
          name: vendor.name
        }));
        setVendorList(vendors);
      })
      .catch(error => console.error('Error fetching vendor details:', error));

    // Fetch plan details
    fetch("https://demo.claytelecom.com/api/Authentication/Get_all_Plans", { headers })
      .then(response => response.json())
      .then(data => {
        const parsedData = JSON.parse(data.jsondata);

        // Extracting names and IDs from the fetched data
        const plans = parsedData.map(plan => ({
          id: plan.p_id,
          name: plan.plan_name,
          vendorId: plan.v_id,
          clientId: plan.c_id,
        }));
        setPlanList(plans);
      })
      .catch(error => console.error('Error fetching plan details:', error));

    // Fetch brand details
    fetch("https://demo.claytelecom.com/api/Authentication/Get_brand", { headers })
      .then(response => response.json())
      .then(data => {
        const parsedData = JSON.parse(data.jsondata);

        // Extracting names and IDs from the fetched data
        const brands = parsedData.map(brand => ({
          id: brand.brand_id,
          name: brand.brand_name
        }));
        setBrandList(brands);
      })
      .catch(error => console.error('Error fetching brand details:', error));

    // Fetch total stock value
    fetch(`https://demo.claytelecom.com/api/Authentication/Get_inventory_stock_value?brandid=${selectedBrandId}&vendorid=${selectedVendorId}`, { headers })
      .then(response => response.json())
      .then(data => {
        // Log the entire response
        console.log('Total Stock API Response:', data);

        // Parse the JSON string in 'jsondata' field
        const jsonData = JSON.parse(data.jsondata);

        // Assuming the API response contains a field named "COUNT(`Id`)"
        const countValue = jsonData[0]['COUNT(`Id`)'] || 0;
        setTotalStock(countValue);

        // Calculate stockLeft
        const calculatedStockLeft = countValue - quantityRequired;
        setStockLeft(calculatedStockLeft);
      })
      .catch(error => console.error('Error fetching total stock value:', error));
  }, [quantityRequired, selectedBrandId, selectedVendorId, token]);

  const handleClientChange = (event) => {
    setSelectedClientId(event.target.value);
  };

  const handleVendorChange = (event) => {
    setSelectedVendorId(event.target.value);
  };

  const handlePlanChange = (event) => {
    setSelectedPlanId(event.target.value);
  };

  const handleBrandChange = (event) => {
    setSelectedBrandId(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantityRequired(event.target.value);
  };

  const handleAssignInventory = () => {
    // Make a request to Assign_inventory_all with the selected client, vendor, plan, brand IDs, and quantity
    const apiUrl = "https://demo.claytelecom.com/api/Authentication/Assign_inventory_all";
    const requestData = {
      flag: 1,
      quantity_required: quantityRequired,
      vendor_id: selectedVendorId,
      brand_id: selectedBrandId,
      plan_id: selectedPlanId,
      client_id: selectedClientId,
    };

    // Make the API call using fetch or Axios
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include the token here
      },
      body: JSON.stringify(requestData),
    })
      .then(response => response.json())
      .then(data => {
        // Handle the response as needed
        console.log('Assign Inventory Response:', data);
      })
      .catch(error => console.error('Error assigning inventory:', error));

    // Display an alert and navigate to the Invoice page
    alert("THANK YOU, INVENTORY IS ASSIGNED -> INVOICE");
    navigate('/Invoice');
  };

  // Log selected values
  console.log('Brand', selectedBrandId);
  console.log('Client', selectedClientId);
  console.log('Plan', selectedPlanId);

  return (
    <>
      <form className="container mx-auto mt-8 p-4 bg-zinc-50 rounded-lg">
        <h1 className="text-3xl font-bold mb-4">Assign Inventory</h1>

        <label className="block text-sm font-semibold mb-2">Select Brand Name:</label>
        <select value={selectedBrandId} onChange={handleBrandChange} className="w-full p-2 border border-gray-300 rounded">
          <option value="" disabled>Select a brand</option>
          {brandList.map(brand => (
            <option key={brand.id} value={brand.id}>{brand.name}</option>
          ))}
        </select>

        <label className="block text-sm font-semibold mb-2">Vendor Name:</label>
        <select value={selectedVendorId} onChange={handleVendorChange} className="w-full p-2 border border-gray-300 rounded">
          <option value="" disabled>Select a vendor</option>
          {vendorList.map(vendor => (
            <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
          ))}
        </select>

        <label className="block text-sm font-semibold mb-2">Select Client:</label>
        <select
          value={selectedClientId}
          onChange={handleClientChange}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="" disabled>Select a client</option>
          {clientList.map(client => (
            <option key={client.id} value={client.id}>{client.name}</option>
          ))}
        </select>

        <br />

        <label className="block text-sm font-semibold mb-2">Plan Name:</label>
        <select value={selectedPlanId} onChange={handlePlanChange} className="w-full p-2 border border-gray-300 rounded">
          <option value="" disabled>Select a plan</option>
          {planList.map(plan => (
            <option key={plan.id} value={plan.id}>{plan.name}</option>
          ))}
        </select>

        <br />

        <br />

        <div className="container mx-auto mt-8 p-4 bg-zinc-200 rounded-lg">
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
              <label className="block mb-2 text-gray-700">Quantity Required:</label>
              <input
                type="number"
                value={quantityRequired}
                onChange={handleQuantityChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
              <label className="block mb-2 text-gray-700">Total Stock:</label>
              <span className="block p-2 bg-white border border-gray-300 rounded">{totalStock}</span>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
              <label className="block mb-2 text-gray-700">Stock Left:</label>
              <span className="block p-2 bg-white border border-gray-300 rounded">{stockLeft}</span>
            </div>
          </div>
        </div>

        <div className="w-full mb-4 flex justify-center">
          <button
            style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleAssignInventory}
          >
            Assign Inventory
          </button>

          <button
            type="button"
            onClick={AssignDetail}
            style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Assign Details
          </button>
        </div>
      </form>
    </>
  );
}

export default AssignInventory;
