import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Logoinp from '../images/ClayLogo.png'

const PreviewClay = ({ selectedInvoice }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

    if (!selectedInvoice) {
      return null;
    }

   
  const renderItems = () => {
    return selectedInvoice.items.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.name}</td>
        <td>{item.description}</td>
        <td>{item.unitPrice}</td>
        <td>{item.discount}</td>
        <td>{item.total}</td>
      </tr>
    ));
  };
    return (
<>
<div style={{ maxWidth: '100%', overflowX: 'auto' }}>

      <div ref={componentRef}>
        <div style={{ maxWidth: "1440px", margin: "auto" }}>
            {/* <div style={{ width: "150px", margin: "auto" }}>
                <img width="150" height="90" src="../images/claycrop.png" alt="claycrop" />
            </div>
            <br /> */}
            <hr style={{ fontWeight: "bold", color: "#cfac94", borderWidth: "2px", borderColor: "#c4652d" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <p className="s1" style={{ paddingTop: "5pt", paddingLeft: "6pt", lineHeight: "114%", textAlign: "left", fontWeight: "bold", fontSize: "29px", color: "black" }}>CLAY TECHNOLOGIES PTE LTD</p>
                    <br />
                    <p className="s1" style={{ paddingTop: "5pt", paddingLeft: "6pt", lineHeight: "114%", textAlign: "left", fontWeight: "bold", fontSize: "20px", color: "black" }}>30 CECIL STREET #19-08</p>
                    <p className="s1" style={{ paddingTop: "5pt", paddingBottom: "5pt", paddingLeft: "6pt", lineHeight: "114%", textAlign: "left", fontWeight: "bold", fontSize: "19px", color: "black" }}>PRUDENTIAL TOWER SINGAPORE(049712)</p>
                </div>
                <div>
                    <p className="s1" style={{ paddingTop: "5pt", paddingRight: "6pt", lineHeight: "114%", textAlign: "right", fontWeight: "bold", fontSize: "29px", color: "#452e1e" }}>Invoice No: 7852699</p>
                </div>
            </div>
            <hr style={{ fontWeight: "bold", color: "#cfac94", borderWidth: "2px", borderColor: "#c4652d" }} />
            <br /><br />
            <hr style={{ fontWeight: "bold", color: "#cfac94", borderWidth: "2px", borderColor: "#cfac94" }} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <p className="s1" style={{ paddingTop: "5pt", paddingLeft: "6pt", lineHeight: "114%", textAlign: "left", fontWeight: "bold", fontSize: "20px", color: "black" }}>INVOICE :{selectedInvoice.invoiceno}</p>
                </div>
                <div>
                    <p className="s1" style={{ paddingTop: "5pt", paddingRight: "6pt", lineHeight: "114%", textAlign: "right", fontWeight: "bold", fontSize: "20px", color: "black" }}>Date: {selectedInvoice.invoice_date}</p>
                </div>
            </div>
            <br />
            <hr style={{ fontWeight: "bold", color: "#8b5e3f", borderWidth: "2px", borderColor: "#cfac94" }} />
            <p className="s1" style={{ paddingTop: "5pt", paddingBottom: "15pt", paddingLeft: "6pt", lineHeight: "114%", textAlign: "right", fontWeight: "bold", fontSize: "19px", backgroundColor: "#e7d5ca", color: "black" }}>Due Date: {selectedInvoice.Due_date}</p>
            <p className="s1" style={{ paddingTop: "5pt", paddingBottom: "15pt", paddingLeft: "6pt", lineHeight: "114%", textAlign: "left", fontWeight: "bold", fontSize: "19px", backgroundColor: "#f2f2f2", color: "black" }}>Billing Month:{selectedInvoice.billing_date}</p>
            <hr style={{ fontWeight: "bold", color: "#cfac94", borderWidth: "2px", borderColor: "#8b5e3f" }} />
            <br /><br />
            {/* <p style={{ paddingTop: "5pt", paddingLeft: "10pt", lineHeight: "114%", textAlign: "left", color: "#3b240f", fontWeight: "500" }}>Company Name</p>
            <p style={{ paddingTop: "5pt", paddingLeft: "10pt", lineHeight: "114%", textAlign: "left", color: "#3b240f", fontWeight: "500" }}>Address</p>
            <br /><br /> */}
               <table className="table" style={{ width: "100%", border: "2px solid #c4652d" }}>
            <thead style={{ color: "#213f43", borderBottom: "2px solid #c4652d" }}>
                <tr>
                    <th style={{ border: "2px solid #c4652d" }}>S.No.</th>
                    <th style={{ border: "2px solid #c4652d" }}>Item</th>
                    <th style={{ border: "2px solid #c4652d" }}>Product description</th>
                    <th style={{ border: "2px solid #c4652d" }}>QTY</th>
                    <th style={{ border: "2px solid #c4652d" }}>Discount</th>
                    <th style={{ border: "2px solid #c4652d" }}>Price unit</th>
                    <th style={{ border: "2px solid #c4652d" }}>Amount</th>
                </tr>
            </thead>
            <tbody style={{ backgroundColor: "#f2d8c8" }}>
                <tr>
                    <td style={{ border: "2px solid #c4652d" }}>1.</td>
                    <td style={{ border: "2px solid #c4652d" }}>{selectedInvoice.item}</td>
                    <td style={{ border: "2px solid #c4652d", backgroundColor: "#f2d8c8" }}>{selectedInvoice.Description}</td>
                    <td style={{ border: "2px solid #c4652d" }}>{selectedInvoice.qty}</td>
                    <td style={{ border: "2px solid #c4652d" }}>-</td>
                    <td style={{ border: "2px solid #c4652d" }}>{selectedInvoice.Unit_price}</td>
                    <td style={{ border: "2px solid #c4652d" }}>USD {selectedInvoice.amount}</td>
                </tr>
                <tr>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <th style={{ border: "2px solid #c4652d" }}></th>
                    <th style={{ border: "2px solid #c4652d" }}></th>
                </tr>
            </tbody>
            <tfoot>
                <tr style={{ backgroundColor: "#f2d8c8" }}>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <th style={{ backgroundColor: "#f2d8c8", border: "2px solid #c4652d" }}>SUBTOTAL</th>
                    <td style={{ border: "2px solid #c4652d" }}>USD {selectedInvoice.amount}.00</td>
                </tr>
                <tr>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }} colspan="2">Currency Exchange per unit</td>
                    <td style={{ border: "2px solid #c4652d" }}>1.0000 USD</td>
                </tr>
                <tr>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <td style={{ border: "2px solid #c4652d" }}></td>
                    <th style={{ border: "2px solid #c4652d" }} colspan="2">Total Amount in USD</th>
                    <th style={{ border: "2px solid #c4652d" }}>US$ {selectedInvoice.amount}.00</th>
                </tr>
            </tfoot>
        </table>
 
            <br /><br />
            <h3 style={{ paddingTop: "2pt", paddingLeft: "15pt", textIndent: "0pt", textAlign: "left", color: "grey", fontSize: "19px", textDecoration: "underline" }}>Please make the electronic wire transfer to:</h3>
            <br />
            <hr style={{ fontWeight: "bold", color: "#cfac94", borderWidth: "2px", borderColor: "#cfac94" }} />
            <div style={{ color: "#595959", paddingTop: "10px", paddingBottom: "10px" }}>
                <p style={{ paddingLeft: "15pt", paddingBottom: "10px", textIndent: "0pt", lineHeight: "114%", textAlign: "left" }}>
                    <span className="s9">Company Name: </span>
                    <span className="s9" style={{ fontWeight: "bold" }}>{selectedInvoice.selectedClient ? selectedInvoice.selectedClient.company_name : 'N/A'}</span>
                </p>
                <p style={{ paddingLeft: "15pt", paddingBottom: "10px", textIndent: "0pt", lineHeight: "114%", textAlign: "left" }}>
                    <span className="s9">Company Bank A/C No : </span>
                    <span className="s9" style={{ fontWeight: "bold" }}>{selectedInvoice.selectedBank ? selectedInvoice.selectedBank.bank_account_number : 'N/A'}</span>
                    <span className="s9"> </span>
                </p>
                <p style={{ paddingLeft: "15pt", paddingBottom: "10px", textIndent: "0pt", lineHeight: "114%", textAlign: "left" }}>
                    <span className="s9">Company Bank Name: </span>
                    <span className="s9" style={{ fontWeight: "bold" }}>{selectedInvoice.selectedBank ? selectedInvoice.selectedBank.bank_name : 'N/A'}</span>
                </p>
                <p style={{ paddingLeft: "15pt", paddingBottom: "10px", textIndent: "0pt", lineHeight: "114%", textAlign: "left" }}>
                    <span className="s9">SWIFT Address/Code:</span>
                    <span className="s9" style={{ fontWeight: "bold" }}>85471293688</span>
                </p>
                <p style={{ paddingLeft: "15pt", paddingBottom: "10px", textIndent: "0pt", lineHeight: "114%", textAlign: "left" }}>
                    <span className="s9">CHIPS UID Number</span>
                    <span className="s9" style={{ fontWeight: "bold" }}>121000358</span>
                    <span className="s9"> </span>
                </p>
                <p style={{ paddingLeft: "15pt", paddingBottom: "5px", textIndent: "0pt", lineHeight: "114%", textAlign: "left" }}>
                    <span className="s9"> </span>
                </p>
            </div>
            <hr style={{ fontWeight: "bold", color: "#cfac94", borderWidth: "2px", borderColor: "#cfac94" }} />
            <br />
            <p style={{ textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>THANK YOU FOR YOUR BUSINESS!!</p>
            </div>
            </div>
</div>
            <div className="w-full mb-4 flex justify-center">
                <button
                    style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
                    className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    onClick={handlePrint}>Download  </button>

            </div>
</>
   );
  };
  
  
  export default PreviewClay;