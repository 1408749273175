import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboard from './components/Dashboard';
import Vendor from './components/Vendor';
import Signup from './components/Signup';
import Gighub from './components/Gighub';
import TerrahHub from './components/TerrahHub';
import AirhubSystems from './components/AirhubSystems';
import WebsiteGighub from './components/WebsiteGighub';
import UserLogin from './components/UserLogin';
import Homepage from './components/Homepage';
import Viewsalesinvoices from './components/Viewsalesinvoices';
import Vendordetails from './components/Vendordetails';
import Productdetails from './components/Productdetails';
import CreateProduct from './components/CreateProduct';
import Client from './components/Client';
import Clientdetails from './components/Clientdetails';
import CreateProductdetails from './components/CreateProductdetails';
import Createplan from './components/Createplan';
import Details from './components/Details';
import ClayTech from './components/ClayTech';
import ContactUs from './components/ContactUs';
import Getplan from './components/Getplan';
import Fileupload from './components/Fileupload';
import Fileuploaddetails from './components/Fileuploaddetails';
import Invoice from './components/Invoice';
import CurrencyUpdateForm from './components/CurrencyUpdate';
import Currencydetails from './components/Currencydetails'
import Invoicedetails from './components/Invoicedetails'
import Invoicedatapdf from './components/Invoicedatapdf'

import CTSInvoice from './components/email/Clay'
import GighubInvoice from './components/email/Gighub'
import PentaInvoice from './components/email/Pentagram'
import AirhubInvoice from './components/email/AirhubSystems'
import InvoiceTable from './components/Invoicepp'
import InvoicePreviewTemplate from './components/InvoicePreviewTemplate'
import Getallplans from './components/Getallplans'
import Temporary from './components/Temporary'
import AssignInventory from './components/AssignInventory'
import GetSalesdetails from './components/GetSalesdetails'
import TestInvoice from './components/Testinvoice'
import TestInvoice2 from './components/TestInvoice2'
import TestInvoice3 from './components/TestInvoice3'
import TestInvoice4 from './components/TestInvoice4'
import CreateUser from './components/CreateUser'
import InvoiceDate from './components/InvoiceDate'
import InvoiceEditDetails from './components/InvoiceEditDetails'
import AssignDetails from './components/AssignDetails'
import Preview from './components/Preview'
import Preview2 from './components/Preview2'
import TestInvoice5 from './components/Testinvoice5'
import PreviewClay from './components/PreviewClay'
import PreviewPentagram from './components/PreviewPentagram'
import PreviewAirhub from './components/PreviewAirhub'
import PreviewGighub from './components/PreviewGighub'
import InsertBankForm from './components/Insertbank';
import GetBank from './components/GetBank';
import DownloadPDFButton from './components/DownloadPDFButton';
import AssignDetailsdraft from './components/AssignDetailsdraft';



function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loggedIn, setLoggedIn] = useState(!!token);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      setLoggedIn(true);
    }
  }, []);

  const setAuthToken = (dataToken) => {
    localStorage.setItem('token', dataToken);
    setToken(dataToken);
    setLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setLoggedIn(false);
  };

  return (
    <Router>
      <Routes>
      {/* {loggedIn && <Route path="Homepage" element={<Homepage />} />} */}

        {loggedIn ? (
          <Route path="/" element={<Layout />}>
            <Route path="Dashboard" element={<Dashboard />} />    
            <Route path="Gighub" element={<Gighub />} />    
            <Route path="ClayTech" element={<ClayTech />} />    
            <Route path="TerrahHub" element={<TerrahHub />} />    
            <Route path="AirhubSystems" element={<AirhubSystems />} />    
            <Route path="WebsiteGighub" element={<WebsiteGighub />} />    

            <Route path="Viewsalesinvoices" element={<Viewsalesinvoices />} />    
            <Route path="ContactUs" element={<ContactUs />} />  

            <Route path="Vendor" element={<Vendor />} />    
            <Route path="Vendordetails" element={<Vendordetails />} />    
            <Route path="Client" element={<Client />} />    
            <Route path="Clientdetails" element={<Clientdetails />} />    
            <Route path="CreateProduct" element={<CreateProduct />} />    
            <Route path="productdetails" element={<Productdetails />} />    
            <Route path="CreateProductdetails" element={<CreateProductdetails />} />    
            <Route path="Createplan" element={<Createplan />} />    
            <Route path="Details" element={<Details />} />    
            <Route path="Getplan" element={<Getplan />} />   
            <Route path="Fileupload" element={<Fileupload />} />    
            <Route path="Fileuploaddetails" element={<Fileuploaddetails />} />    
            <Route path="Invoice" element={<Invoice />} />   
          {/* <Route path="Table" element={<Table />} />     */}
          <Route path="CurrencyUpdate" element={<CurrencyUpdateForm />} />    
          <Route path="Currencydetails" element={<Currencydetails />} />    
          <Route path="Invoicedetails" element={<Invoicedetails />} />    
          <Route path="Invoicedatapdf" element={<Invoicedatapdf />} />   
          <Route path="InvoiceTable" element={<InvoiceTable />} />   
          
          <Route path="AssignInventory" element={<AssignInventory />} />   
          {/* <Route path="AsssignInventory" element={<AsssignInventory />} />    */}
          <Route path="GetSalesdetails" element={<GetSalesdetails />} />   

          
          <Route path="InvoicePreviewTemplate" element={<InvoicePreviewTemplate />} />   
          <Route path="Getallplans" element={<Getallplans />} />   
          <Route path="TestInvoice" element={<TestInvoice />} />   
          <Route path="TestInvoice2" element={<TestInvoice2 />} />   
          <Route path="TestInvoice3" element={<TestInvoice3 />} />   
          <Route path="TestInvoice4" element={<TestInvoice4 />} />   
          <Route path="CreateUser" element={<CreateUser />} />   
          <Route path="InvoiceDate" element={<InvoiceDate />} />   
          <Route path="InvoiceEditDetails" element={<InvoiceEditDetails />} />   
          <Route path="AssignDetails" element={<AssignDetails />} />   
          <Route path="Preview" element={<Preview />} /> 
          <Route path="Preview2" element={<Preview2 />} />   
          <Route path="TestInvoice5" element={<TestInvoice5 />} />   
          
          <Route path="PreviewClay" element={<PreviewClay />} />   
          <Route path="PreviewPentagram" element={<PreviewPentagram />} />   
          <Route path="PreviewAirhub" element={<PreviewAirhub />} />   
          <Route path="PreviewGighub" element={<PreviewGighub />} />  
          <Route path="InsertBankForm" element={<InsertBankForm />} />   
          <Route path="GetBank" element={<GetBank />} />   
          <Route path="DownloadPDFButton" element={<DownloadPDFButton />} />   
          <Route path="AssignDetailsdraft" element={<AssignDetailsdraft />} />   

          
          <Route path="Temporary" element={<Temporary />} />   

            <Route path="Logout" element={<UserLogin handleLogout={handleLogout} />} />
          </Route>
        ) : (
          <>
          <Route path="CTSInvoice" element={<CTSInvoice />} />   
        <Route path="PentaInvoice" element={<PentaInvoice />} /> 
        <Route path="AirhubInvoice" element={<AirhubInvoice />} />
        <Route path="GighubInvoice" element={<GighubInvoice />} /> 

        <Route path="Homepage" element={<Homepage />} />

            <Route path="Signup" element={<Signup setAuthToken={setAuthToken} />} />
            <Route index element={<UserLogin />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
