import React, { useState, useEffect } from 'react';

const InvoiceSearch = () => {
  const [invoices, setInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://demo.claytelecom.com/api/Authentication/Get_invoice_by_date?fromDate=${startDate}&toDate=${endDate}`
        );
        const data = await response.json();
        setInvoices(data.jsondata ? JSON.parse(data.jsondata) : []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Check if both start and end dates are available before making the API call
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]); // Run this effect whenever start or end date changes

  const handleSearch = () => {
    // API call is already handled by useEffect, no need to duplicate it here
    // Perform additional search logic if needed
    const filteredInvoices = invoices.filter((invoice) =>
      invoice.clientname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    // Update the state with the filtered invoices
    setInvoices(filteredInvoices);
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-zinc-200 rounded-lg">
      <div className="flex flex-wrap -mx-2">
        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <input
            type="date"
            placeholder="From Date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <input
            type="date"
            placeholder="To Date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">

        <button onClick={handleSearch}
        >Search</button>
</div>
        <table>
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Amount</th>
              <th>Created Date</th>
              <th>Updated Date</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, index) => (
              <tr key={index}>
                <td>{invoice.clientname}</td>
                <td>{invoice.amount}</td>
                <td>{invoice.created_date}</td>
                <td>{invoice.updated_date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvoiceSearch;
