import React, { useState, useEffect } from 'react';

const GetBank = () => {
  const [brands, setBrands] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [banks, setBanks] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState('');
  const token = localStorage.getItem('token'); // Get token from localStorage

  const fetchBrands = () => {
    fetch('https://demo.claytelecom.com/api/Authentication/Get_brand', {
      headers: {
        'Authorization': `Bearer ${token}`, // Include token in headers
      },
    })
      .then(response => response.json())
      .then(data => {
        if (typeof data === 'object' && data.jsondata) {
          const jsonDataArray = JSON.parse(data.jsondata);
          if (Array.isArray(jsonDataArray)) {
            setBrands(jsonDataArray);
          } else {
            console.error('Invalid jsondata structure in brands:', jsonDataArray);
          }
        } else {
          console.error('Invalid brands data structure:', data);
        }
      })
      .catch(error => console.error('Error fetching brand details:', error));
  };

  const fetchBanksByBrand = async (brandId) => {
    try {
      const response = await fetch(`https://demo.claytelecom.com/api/Authentication/Get_bank_byid?brandid=${brandId}`, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in headers
        },
      });
      const data = await response.json();
      if (data.isSuccess) {
        const jsonDataArray = JSON.parse(data.jsondata);
        if (Array.isArray(jsonDataArray)) {
          setBanks(jsonDataArray);
        } else {
          console.error('Invalid jsondata structure in banks:', jsonDataArray);
        }
      } else {
        console.error('Error in API response:', data.message);
      }
    } catch (error) {
      console.error('Error fetching banks:', error);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  const handleBrandChange = (event) => {
    const selectedBrandId = event.target.value;
    setSelectedBrandId(selectedBrandId);
    fetchBanksByBrand(selectedBrandId);
  };

  return (
    <div>
      <h1 className="text-center text-3xl font-bold mb-6">Bank Details</h1>
      <label className="block text-sm font-semibold mb-2" htmlFor="brandSelect">Select Brand:</label>
      <select id="brandSelect" onChange={handleBrandChange} className="w-full p-2 border border-gray-300 rounded" value={selectedBrandId}>
        <option value="0">Select a Brand</option>
        {brands.map(brand => (
          <option key={brand.brand_id} value={brand.brand_id}>
            {brand.brand_name}
          </option>
        ))}
      </select>
      <table className="w-full mt-4 border-collapse border border-gray-200">
        <thead>
          <tr>
            <th className="border px-4 py-2">Bank Id</th>
            <th className="border px-4 py-2">Bank Name</th>
            <th className="border px-4 py-2">Bank Account Number</th>
            <th className="border px-4 py-2">Routing Number</th>
            <th className="border px-4 py-2">Bank Address</th>
            <th className="border px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {banks.map(bank => (
            <tr key={bank.bank_id}>
              <td className="border px-4 py-2">{bank.bank_id}</td>
              <td className="border px-4 py-2">{bank.bank_name}</td>
              <td className="border px-4 py-2">{bank.bank_account_number}</td>
              <td className="border px-4 py-2">{bank.routing_number}</td>
              <td className="border px-4 py-2">{bank.bank_address}</td>
              <td className="border px-4 py-2">
                <button className="text-blue-500 hover:underline">Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GetBank;
