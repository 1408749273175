import React, { useState, useEffect } from 'react';

// Utility function to get the token from local storage
const getAuthToken = () => localStorage.getItem('authToken');

const Viewsalesinvoices = () => {
  const [vendors, setVendors] = useState([]);
  const [updateVendorData, setUpdateVendorData] = useState({});
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  useEffect(() => {
    // Fetch vendors on component mount
    fetchVendors();
  }, []);

  const fetchVendors = async () => {
    const token = getAuthToken();
    if (!token) {
      console.error('No auth token found');
      return;
    }

    try {
      const response = await fetch('https://demo.claytelecom.com/api/Authentication/Getvendordetail?flag=1', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const responseData = await response.json();

      // Check if jsondata is present and parse it into an array
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      // Check if data is an array before setting it
      if (Array.isArray(data)) {
        setVendors(data);
      } else {
        console.error('Parsed data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleUpdateClick = (vendor) => {
    setUpdateVendorData(vendor);
    setIsUpdateMode(true);
  };

  const handleCancelUpdate = () => {
    setIsUpdateMode(false);
    setUpdateVendorData({});
  };

  const handleUpdateVendor = async () => {
    const token = getAuthToken();
    if (!token) {
      console.error('No auth token found');
      return;
    }

    try {
      const response = await fetch('https://demo.claytelecom.com/api/Authentication/UpdateVendor', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(updateVendorData),
      });
      const data = await response.json();
      console.log('Update Vendor Data:', updateVendorData);

      console.log('Vendor updated successfully:', data);

      // Fetch vendors again to update the table
      fetchVendors();

      // Reset update mode and data
      setIsUpdateMode(false);
      setUpdateVendorData({});
    } catch (error) {
      console.error('Error updating vendor:', error);
    }
  };

  return (
    <div>
      <h2>Vendor Table</h2>
      <table>
        <thead>
          <tr>
            <th>Vendor ID</th>
            <th>User Name</th>
            <th>Email</th>
            <th>Mobile No</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {vendors.map((vendor) => (
            <tr key={vendor.v_id}>
              <td>{vendor.v_id}</td>
              <td>{vendor.name}</td>
              <td>{vendor.emailid}</td>
              <td>{vendor.company_name}</td>

              <td>
                <button onClick={() => handleUpdateClick(vendor)}>Update</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isUpdateMode && (
        <div>
          <h2>Update Vendor</h2>
          <input type="text" placeholder="User Name" value={updateVendorData.name || ''} onChange={(e) => setUpdateVendorData({ ...updateVendorData, name: e.target.value })} />
          <input type="text" placeholder="Last Name" value={updateVendorData.lastname || ''} onChange={(e) => setUpdateVendorData({ ...updateVendorData, lastname: e.target.value })} />
          <input type="text" placeholder="Email ID" value={updateVendorData.emailid || ''} onChange={(e) => setUpdateVendorData({ ...updateVendorData, emailid: e.target.value })} />
          <input type="text" placeholder="Phone No" value={updateVendorData.phone_no || ''} onChange={(e) => setUpdateVendorData({ ...updateVendorData, phone_no: e.target.value })} />
          <input type="text" placeholder="Phone Number" value={updateVendorData.phonenumber || ''} onChange={(e) => setUpdateVendorData({ ...updateVendorData, phonenumber: e.target.value })} />
          <input type="text" placeholder="Website" value={updateVendorData.website || ''} onChange={(e) => setUpdateVendorData({ ...updateVendorData, website: e.target.value })} />
          <input type="text" placeholder="Company Name" value={updateVendorData.company_name || ''} onChange={(e) => setUpdateVendorData({ ...updateVendorData, company_name: e.target.value })} />
          <input type="text" placeholder="Contact Number" value={updateVendorData.contact_number || ''} onChange={(e) => setUpdateVendorData({ ...updateVendorData, contact_number: e.target.value })} />
          <input type="text" placeholder="Zip Code" value={updateVendorData.zip_code || ''} onChange={(e) => setUpdateVendorData({ ...updateVendorData, zip_code: e.target.value })} />
          <input type="text" placeholder="Fax" value={updateVendorData.fax || ''} onChange={(e) => setUpdateVendorData({ ...updateVendorData, fax: e.target.value })} />

          <button onClick={handleUpdateVendor}>Update</button>
          <button onClick={handleCancelUpdate}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default Viewsalesinvoices;
