import React, { useState, useEffect } from 'react';

const CreateProductdetails = () => {
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error handling

  useEffect(() => {
    fetchProductData();
  }, []);

  const fetchProductData = async () => {
    setLoading(true);
    setError(null); // Reset error state before fetching

    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Authentication token is not available');
      }

      const response = await fetch(
        'https://demo.claytelecom.com/api/Authentication/Getproductdetail?flag=1',
        {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the request headers
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      if (Array.isArray(data)) {
        setProductData(data);
      } else {
        console.error('Parsed data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load product details. Please try again later.');
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };
  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
      <h1 className="text-center text-3xl font-bold mb-6">Product Details</h1>

      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">ID</th>
            <th className="py-2 px-4 border-b">First Name</th>
            <th className="py-2 px-4 border-b">Last Name</th>
            <th className="py-2 px-4 border-b">Phone Number</th>
            <th className="py-2 px-4 border-b">Company Name</th>
            <th className="py-2 px-4 border-b">Contact Number</th>
            <th className="py-2 px-4 border-b">Zip Code</th>
            <th className="py-2 px-4 border-b">Phone No</th>
            <th className="py-2 px-4 border-b">Fax</th>
            <th className="py-2 px-4 border-b">Mobile Number</th>
          </tr>
        </thead>
        <tbody>
          {productData.map((product) => (
            <tr key={product.p_id}>
              <td className="py-2 px-4 border-b">{product.p_id}</td>
              <td className="py-2 px-4 border-b">{product.name}</td>
              <td className="py-2 px-4 border-b">{product.lastname}</td>
              <td className="py-2 px-4 border-b">{product.phonenumber}</td>
              <td className="py-2 px-4 border-b">{product.company_name}</td>
              <td className="py-2 px-4 border-b">{product.contact_number}</td>
              <td className="py-2 px-4 border-b">{product.zip_code}</td>
              <td className="py-2 px-4 border-b">{product.phone_no}</td>
              <td className="py-2 px-4 border-b">{product.fax}</td>
              <td className="py-2 px-4 border-b">{product.mobile_number}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CreateProductdetails;
