import {
	HiOutlineViewGrid,
	HiOutlineCube,
	HiOutlineShoppingCart,
	HiOutlineDocumentText,
	HiOutlineLogout,
	HiOutlineCog,
	HiOutlineQuestionMarkCircle,
	HiOutlineAnnotation,
} from 'react-icons/hi'
import { CiBank } from "react-icons/ci";
import { CgWebsite } from "react-icons/cg";

export const DASHBOARD_SIDEBAR_LINKS = [
	{
		key: 'Home',
		label: 'Home',
		path: '/Homepage',
		icon: <HiOutlineViewGrid style={{ color: 'white' }}/>
	},
	{
		key: 'dashboard',
		label: 'Dashboard',
		path: '/Dashboard',
		icon: <HiOutlineDocumentText style={{ color: 'white' }}/>
	},
	{
		key: 'Gighub',
		label: 'Gighub',
		path: '/Gighub',
		icon: <HiOutlineCube style={{ color: 'white' }}/>
	},
	// {
	// 	key: 'ClayTech Systems LLP',
	// 	label: 'ClayTech Systems',
	// 	path: '/ClayTech',
	// 	icon: <HiOutlineCog style={{ color: 'white' }}/>
	// },
	// {
	// 	key: 'Vendor',
	// 	label: 'Vendor',
	// 	path: '/Vendor',
	// 	icon: <HiOutlineShoppingCart style={{ color: 'white' }}/>
	// },


	// {
	// 	key: 'AirhubSystems',
	// 	label: 'Airhub Systems',
	// 	path: '/AirhubSystems',
	// 	icon: <HiOutlineAnnotation style={{ color: 'white' }}/>
	// },
	{
		key: 'WebsiteGighub',
		label: 'Website Gighub',
		path: '/WebsiteGighub',
		icon: <CgWebsite style={{ color: 'white' }}/>
	},	
	
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
	// {
	// 	key: 'settings',
	// 	label: 'Settings',
	// 	path: '/settings',
	// 	icon: <HiOutlineCog style={{ color: 'white' }}/>
	// },
	{
		key: 'support',
		label: 'Gighub Solution',
		path: 'https://gighubinc.com/our-solutions/',
		icon: <HiOutlineQuestionMarkCircle style={{ color: 'white' }}/>
	},
	{
		key: 'Logout',
		label: 'Logout',
		path: '/',
		icon: <HiOutlineLogout style={{ color: 'white' }}/>
	},
]