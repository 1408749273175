import React, { useEffect, useState } from 'react';
import GighubInvoice from './InvoicePreviewTemplate';

const InvoiceTable = () => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://demo.claytelecom.com/api/Authentication/Get_invoice_details');
        const data = await response.json();

        // Parse the 'jsondata' string into an array
        const jsonDataArray = JSON.parse(data.jsondata);

        setInvoiceData(jsonDataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { key: 'Id', header: 'ID', className: 'py-2' },
    { key: 'customer_name', header: 'Customer Name', className: 'py-2' },
    { key: 'order_no', header: 'Order Number', className: 'py-2' },
    { key: 'invoice_name', header: 'Invoice Name', className: 'py-2' },
    { key: 'Brand_id', header: 'Brand_id', className: 'py-2' },
    { key: 'qty', header: 'Qty', className: 'py-2' },
    { key: 'amount', header: 'Amount', className: 'py-2' },
    { key: 'client_id', header: 'Client ID', className: 'py-2' },
  ];

  const renderTableHeader = () => {
    return (
      <tr>
        {columns.map((column) => (
          <th key={column.key} className={column.className}>
            {column.header}
          </th>
        ))}
        <th>Action</th>
      </tr>
    );
  };

  const renderTableData = () => {
    return invoiceData.map((invoice) => (
      <tr key={invoice.Id}>
        {columns.map((column) => (
          <td key={column.key} className={column.className}>
            {invoice[column.key]}
          </td>
        ))}
        <td>
          <button onClick={() => handlePreviewClick(invoice)}>Preview</button>
          <button onClick={() => handleSendClick(invoice)}>Send</button>
        </td>
      </tr>
    ));
  };

  const handlePreviewClick = async (invoice) => {
    try {
      const brandId = invoice.Brand_id;
      const responseBrand = await fetch(`https://demo.claytelecom.com/api/Authentication/Get_bank_byid?brandid=${brandId}`);
      const dataBrand = await responseBrand.json();

      const jsonDataArrayBrand = JSON.parse(dataBrand.jsondata);
      const selectedBank = jsonDataArrayBrand[0];

      const clientId = invoice.client_id;
      const responseClient = await fetch('https://demo.claytelecom.com/api/Authentication/Get_clientdetails?flag=1');
      const dataClient = await responseClient.json();

      const jsonDataArrayClient = JSON.parse(dataClient.jsondata);
      const selectedClient = jsonDataArrayClient.find(client => client.c_id === clientId);

      const bankId = invoice.brand_id;
      const responsebank = await fetch('https://demo.claytelecom.com/api/Authentication/Get_brand');
      const databank = await responsebank.json();

      const jsonDataArraybank = JSON.parse(databank.jsondata);
      const selectedbank = jsonDataArraybank.find(bank => bank.bank_id === bankId);
      if (selectedClient) {
        setSelectedInvoice({
          ...invoice,
          selectedBank,
          selectedClient,
          selectedbank,
        });
      } else {
        console.error(`Client details not found for client_id: ${clientId}`);
        console.error(`Bank details not found for bank_id: ${bankId}`);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSendClick = async (invoice) => {
    try {
      const invoiceId = invoice.Id;
      const sendResponse = await fetch(`https://demo.claytelecom.com/api/Authentication/mailing_invoice?invoiceid=${invoiceId}`);
      // Handle the response if needed
      console.log('Invoice sent successfully:', sendResponse);
    } catch (error) {
      console.error('Error sending invoice:', error);
    }
  };

  const renderPreviewModal = () => {
    if (!selectedInvoice) {
      return null;
    }

    const modalStyle = {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      padding: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      width: '100%', // Adjust the width as needed
      maxHeight: '100vh', // Adjust the maxHeight as needed
      overflowY: 'auto', // Enable vertical scrolling if necessary
    };
    const handlePrint = () => {
      window.print();
    };
    return (
      <div style={modalStyle}>
        <button onClick={() => setSelectedInvoice(null)}>Close</button>

        <GighubInvoice selectedInvoice={selectedInvoice} />

        <button onClick={handlePrint}>Print</button>
      </div>
    );
  };

  return (
    <div>
      <h2>Invoice Details PP</h2>
      <table>
        <thead>{renderTableHeader()}</thead>
        <tbody>{renderTableData()}</tbody>
      </table>
      {renderPreviewModal()}
    </div>
  );
};

export default InvoiceTable;
