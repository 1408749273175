import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function AssignDetailsdraft() {
    const [brandList, setBrandList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedVendor, setSelectedVendor] = useState('');
    const [iccidData, setIccidData] = useState([]);
    const [selectedIccids, setSelectedIccids] = useState([]);
    const [clientList, setClientList] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState('');
    const [planList, setPlanList] = useState([]);
    const [selectedPlanId, setSelectedPlanId] = useState('');
    const navigate = useNavigate();

    // This function will be used for navigation when Assign Details button is clicked.
    const AssignDetail = () => {
      navigate('/AssignDetails');
    };

    // Function to get the token from the session
    const getToken = () => {
        return localStorage.getItem('token'); // Assuming the token is stored with key 'token'
    };

    // Check if the token exists
    const isAuthenticated = () => {
        return !!getToken(); // If token exists, return true, otherwise false
    };

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login'); // Redirect to login if not authenticated
            return;
        }

        const token = getToken();

        // Fetch plan details
        fetch("https://demo.claytelecom.com/api/Authentication/Get_all_Plans", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const parsedData = JSON.parse(data.jsondata);

            // Extracting names and IDs from the fetched data
            const plans = parsedData.map(plan => ({
                id: plan.p_id,
                name: plan.plan_name,
                vendorId: plan.v_id,
                clientId: plan.c_id,
            }));
            setPlanList(plans);
        })
        .catch(error => console.error('Error fetching plan details:', error));
    }, []);

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login');
            return;
        }

        const token = getToken();

        // Fetch client details
        fetch("https://demo.claytelecom.com/api/Authentication/Get_clientdetails?flag=1", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const parsedData = JSON.parse(data.jsondata);

            // Extracting names and IDs from the fetched data
            const clients = parsedData.map(client => ({
                id: client.c_id,
                name: `${client.name}`
            }));
            setClientList(clients);
        })
        .catch(error => console.error('Error fetching client details:', error));
    }, []);

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login');
            return;
        }

        const token = getToken();

        // Fetch brand details
        fetch("https://demo.claytelecom.com/api/Authentication/Get_brand", {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const parsedData = JSON.parse(data.jsondata);
            // Extracting names and IDs from the fetched data
            const brands = parsedData.map(brand => ({
                id: brand.brand_id,
                name: brand.brand_name
            }));
            setBrandList(brands);
        })
        .catch(error => console.error('Error fetching brand details:', error));
    }, []);

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login');
            return;
        }

        if (selectedBrand) {
            const token = getToken();

            // Fetch vendor details based on selected brand
            fetch(`https://demo.claytelecom.com/api/Authentication/Getvendordetail?flag=1&brand_id=${selectedBrand}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => response.json())
            .then(data => {
                const parsedData = JSON.parse(data.jsondata);
                // Extracting names and IDs from the fetched data
                const vendors = parsedData.map(vendor => ({
                    id: vendor.v_id,
                    name: vendor.name
                }));
                setVendorList(vendors);
            })
            .catch(error => console.error('Error fetching vendor details:', error));
        }
    }, [selectedBrand]);

    useEffect(() => {
        if (!isAuthenticated()) {
            navigate('/login');
            return;
        }

        if (selectedVendor) {
            const token = getToken();

            // Fetch ICCID data based on selected vendor
            fetch(`https://demo.claytelecom.com/api/Authentication/Get_ICCID_Available?flag=1&v_id=${selectedVendor}&brand_id=${selectedBrand}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => response.json())
            .then(data => {
                // Parse the JSON data and extract ICCID and ID
                const parsedData = JSON.parse(data.jsondata);
                const extractedData = parsedData.map(item => ({
                    id: item.Id,
                    iccid: item.ICCID
                }));
                // Set the extracted data in state
                setIccidData(extractedData);
            })
            .catch(error => console.error('Error fetching data:', error));
        }
    }, [selectedVendor, selectedBrand]);

    const handleBrandChange = (event) => {
        setSelectedBrand(event.target.value);
        setSelectedVendor(''); // Reset selected vendor when brand changes
    };

    const handleVendorChange = (event) => {
        setSelectedVendor(event.target.value);
    };

    const handleClientChange = (event) => {
        setSelectedClientId(event.target.value);
    };

    const handlePlanChange = (event) => {
        setSelectedPlanId(event.target.value);
    };

    const handleIccidChange = (event) => {
        const iccid = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedIccids([...selectedIccids, iccid]);
        } else {
            setSelectedIccids(selectedIccids.filter(id => id !== iccid));
        }
    };

    const assignInventory = () => {
        if (!isAuthenticated()) {
            navigate('/login');
            return;
        }

        const token = getToken();

        const requestBody = {
            flag: 1,
            plan_id: selectedPlanId,
            client_id: selectedClientId,
            iccidlist: selectedIccids
        };

        fetch("https://demo.claytelecom.com/api/Authentication/Assign_inventory_iccid", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(requestBody)
        })
        .then(response => {
            if (response.ok) {
                console.log('Inventory assigned successfully.');
            } else {
                throw new Error('Failed to assign inventory.');
            }
        })
        .catch(error => console.error('Error assigning inventory:', error));
    };

    return (
        <div>
            <h1 className="text-3xl font-bold mb-4">Particular ICCID</h1>
            <div>
                <label className="block text-sm font-semibold mb-2">Select Brand:</label>
                <select id="brand" onChange={handleBrandChange} value={selectedBrand} className="w-full p-2 border border-gray-300 rounded">
                    <option value="">Select Brand</option>
                    {brandList.map(brand => (
                        <option key={brand.id} value={brand.id}>{brand.name}</option>
                    ))}
                </select>
            </div>

            <label className="block text-sm font-semibold mb-2">Select Vendor:</label>
            <select id="vendor" onChange={handleVendorChange} value={selectedVendor} className="w-full p-2 border border-gray-300 rounded">
                <option value="">Select Vendor</option>
                {vendorList.map(vendor => (
                    <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
                ))}
            </select>

            <label className="block text-sm font-semibold mb-2">Select Client</label>
            <select
                value={selectedClientId}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
            >
                <option value="" disabled>Select a client</option>
                {clientList.map(client => (
                    <option key={client.id} value={client.id}>{client.name}</option>
                ))}
            </select>
            <label className="block text-sm font-semibold mb-2">Plan Name:</label>
            <select value={selectedPlanId} onChange={handlePlanChange} className="w-full p-2 border border-gray-300 rounded">
                <option value="" disabled>Select a plan</option>
                {planList.map(plan => (
                    <option key={plan.id} value={plan.id}>{plan.name}</option>
                ))}
            </select>

            <br />
            <br />
            {iccidData.length > 0 && (
                <div className="w-96 px-4 py-4 rounded-md bg-red-200 overflow-auto max-h-48">
                    <h3>Select ICCID(s):</h3>
                    {iccidData.map(item => (
                        <div key={item.id} className="bg-white p-4 rounded shadow-md flex items-center mb-2">
                            <input
                                type="checkbox"
                                id={item.id}
                                value={item.iccid}
                                onChange={handleIccidChange}
                                checked={selectedIccids.includes(item.iccid)}
                            />
                            <label htmlFor={item.id}>{`${item.iccid}`}</label>
                        </div>
                    ))}
                </div>
            )}
            <div className="w-full mb-4 flex justify-center">
                <button
                    onClick={assignInventory}
                    style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
                    className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Assign Inventory
                </button>
                <button
                    onClick={AssignDetail}
                    style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
                    className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Assign Details
                </button>
            </div>
        </div>
    );
}

export default AssignDetailsdraft;
