import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { HiOutlineBell, HiOutlineChatAlt } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Logo from '../images/Logo.png';

export default function Header() {
  const linkStyles = 'text-gray-200 hover:no-underline hover:bg-gray-700 focus:outline-none';
  const userRole = localStorage.getItem('role') || 'admin'; // Assuming role is stored in localStorage

  const renderClientLinks = () => (
    <>
      <Link to="/Client" className={classNames(linkStyles, 'group inline-flex items-center rounded-sm p-0.5 focus:outline-none')}>
        Client
      </Link>
      <Link to="/CreatePlan" className={classNames(linkStyles, 'group inline-flex items-center rounded-sm p-0.5 focus:outline-none')}>
        Plan
      </Link>
      {/* <Link to="/AssignInventory" className={classNames(linkStyles, 'group inline-flex items-center rounded-sm p-0.5 focus:outline-none')}>
        AssignInventory
      </Link> */}
      <Link to="/AssignDetailsdraft" className={classNames(linkStyles, 'group inline-flex items-center rounded-sm p-0.5 focus:outline-none')}>
        AssignInventory
      </Link>
      <Link to="/Invoice" className={classNames(linkStyles, 'group inline-flex items-center rounded-sm p-0.5 focus:outline-none')}>
        Invoice
      </Link>


    </>
  );

  const renderVendorLinks = () => (
    <>
      <Link to="/Vendor" className={classNames(linkStyles, 'group inline-flex items-center rounded-sm p-0.5 focus:outline-none')}>
        Vendor
      </Link>
      <Link to="/CreatePlan" className={classNames(linkStyles, 'group inline-flex items-center rounded-sm p-0.5 focus:outline-none')}>
        Plan
      </Link>
      <Link to="/Fileupload" className={classNames(linkStyles, 'group inline-flex items-center rounded-sm p-0.5 focus:outline-none')}>
        Inventoryupload
      </Link>
    </>
  );

  const renderAdminLinks = () => (
    <>
      {/* Uncomment if needed */}
      <Link to="/CreateUser" className={classNames(linkStyles, 'group inline-flex items-center rounded-sm p-0.5 focus:outline-none')}>
        CreateUser
      </Link>
      {/* <Link to="/Currencydetails" className={classNames(linkStyles, 'group inline-flex items-center rounded-sm p-0.5 focus:outline-none')}>
        Currency
      </Link> */}
    </>
  );

  return (
<div className="bg-black h-16 px-4 flex items-center border-b border-white justify-between rounded-md">
    

      {userRole === 'client' && renderClientLinks()}
      {userRole === 'vendor' && renderVendorLinks()}
      {userRole === 'admin' && (
        <>
          {renderClientLinks()}
          {renderVendorLinks()}
          {renderAdminLinks()}
        </>
      )}

      <div className="flex items-center gap-2">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open && 'bg-gray-100',
                  'group inline-flex items-center rounded-sm p-1 text-gray-700 hover:text-opacity-100 focus:outline-none'
                )}
              >
                <HiOutlineChatAlt fontSize={24} />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
                  <div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
                    <strong className="text-gray-700 font-medium">Messages</strong>
                    <div className="mt-2 py-1 text-sm">This is the messages panel.</div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  open && 'bg-gray-100',
                  'group inline-flex items-center rounded-sm p-1 text-gray-700 hover:text-opacity-100 focus:outline-none'
                )}
              >
                <HiOutlineBell fontSize={24} />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
                  <div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
                    <strong className="text-gray-700 font-medium">Notifications</strong>
                    <div className="mt-2 py-1 text-sm">This is the notification panel.</div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}
