import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

const CreatePlan = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  
  const [clients, setClients] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');
  const [planFormData, setPlanFormData] = useState({
    flag: 1,
    brand_id: '',
    v_id: '',
    planname: '',
    currency: '',
    tax: '',
    buyingprice: '',
    total_price: '',
  });
  const handleNavigateToGetallDetails = () => {
    navigate('/Getallplans');
  };
  // Get the token from localStorage
  const token = localStorage.getItem('token');

  // Function to check for token and redirect if not present
  const checkToken = () => {
    if (!token) {
      alert('You are not authenticated. Please log in.');
      navigate('/login');
    }
  };

  useEffect(() => {
    checkToken();
    fetchCurrencies();
    fetchBrands();
    fetchVendors();
  }, []);

  const fetchBrands = async () => {
    try {
      const response = await fetch(`${apiUrl}Get_brand`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include the token in the headers
        }
      });

      const responseData = await response.json();
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      if (Array.isArray(data)) {
        setBrands(data.map((brand) => ({ id: brand.brand_id, name: brand.brand_name })));
      } else {
        console.error('Parsed brand data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching brand data:', error);
    }
  };

  const fetchVendors = async () => {
    try {
      const response = await fetch(`${apiUrl}Getvendordetail?flag=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include the token in the headers
        }
      });

      const responseData = await response.json();
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      if (Array.isArray(data)) {
        setVendors(data.map((vendor) => ({ value: vendor.v_id, label: vendor.name })));
      } else {
        console.error('Parsed vendor data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const response = await fetch(`${apiUrl}Get_Currencydetails?flag=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include the token in the headers
        }
      });

      const responseData = await response.json();
      const currenciesData = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      if (Array.isArray(currenciesData)) {
        setCurrencies(currenciesData.map((currency) => ({ id: currency.Id, name: currency.curency_name })));
      } else {
        console.error('Parsed currency data is not an array:', currenciesData);
      }
    } catch (error) {
      console.error('Error fetching currency data:', error);
    }
  };

  const handleBrandChange = (e) => {
    const selectedBrandId = e.target.value;
    setSelectedBrand(selectedBrandId);
    setPlanFormData((prevFormData) => ({
      ...prevFormData,
      brand_id: selectedBrandId,
    }));
  };

  const handleVendorChange = (e) => {
    const selectedVendorId = e.target.value;
    setSelectedVendor(selectedVendorId);
    setPlanFormData((prevFormData) => ({
      ...prevFormData,
      v_id: selectedVendorId,
    }));
  };

  const handleCurrencyChange = (e) => {
    const selectedCurrencyId = e.target.value;
    setSelectedCurrency(selectedCurrencyId);
    setPlanFormData({
      ...planFormData,
      currency: selectedCurrencyId,
    });
  };

  const handlePlanChange = (e) => {
    const { name, value } = e.target;
    setPlanFormData({
      ...planFormData,
      [name]: value,
    });
  };

  const calculateTotalPrice = () => {
    const { tax, buyingprice } = planFormData;
    const totalPrice = (parseFloat(tax) * 0.01 * parseFloat(buyingprice) + parseFloat(buyingprice)).toFixed(2);
    setPlanFormData((prevFormData) => ({
      ...prevFormData,
      total_price: totalPrice,
    }));
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [planFormData.tax, planFormData.buyingprice]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}Create_plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include the token in the headers
        },
        body: JSON.stringify(planFormData),  
      });  

      const responseData = await response.json();

      if (response.ok) {
        alert('Plan created successfully. Thank you!');
        setPlanFormData({
          flag: 1,
          brand_id: '',
          v_id: '',
          planname: '',
          currency: '',
          tax: '',
          buyingprice: '',
          total_price: '',
        });
      } else {
        alert('Failed to create plan. Please check your input and try again.');
        console.error('Failed to create plan:', responseData);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  const downloadExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet([planFormData]);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Plan');
    XLSX.writeFile(workbook, 'Plan.xlsx');
  };


  return (
    <form onSubmit={handleSubmit} className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
      <h1 className="text-center text-3xl font-bold mb-6">Create Plan</h1>
      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">Select Brand:</label>
        <select
          value={selectedBrand}
          onChange={handleBrandChange}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="0" >Select a Brand</option>
          {brands.map((brand) => (
            <option key={brand.id} value={brand.id}>
              {brand.name}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-semibold mb-2">Select Vendor:</label>
        <select
          value={selectedVendor}
          onChange={handleVendorChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="0" >Select a Vendor</option>
          {vendors.map((vendor) => (
            <option key={vendor.value} value={vendor.value}>
              {vendor.label}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-wrap -mx-2">
        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">Select Currency:</label>
          <select
            value={selectedCurrency}
            onChange={handleCurrencyChange}
            className="block w-full border border-gray-300 p-2 rounded"
          >
            <option value="0" >Select a Currency</option>
            {currencies.map((currency) => (
              <option key={currency.id} value={currency.id}>
                {currency.name}
              </option>
            ))}
          </select>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">Plan Name:</label>
          <input
            type="text"
            name="planname"
            value={planFormData.planname}
            onChange={handlePlanChange}
            className="block w-full mt-1 p-2 border rounded-md"
            required
          />
        </div>

        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">Tax(%):</label>
          <input
            type="number"
            name="tax"
            value={planFormData.tax}
            onChange={handlePlanChange}
            className="block w-full border border-gray-300 p-2 rounded"
            required
          />
        </div>

        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">Selling Price:</label>
          <input
            type="number"
            name="buyingprice"
            value={planFormData.buyingprice}
            onChange={handlePlanChange}
            className="block w-full mt-1 p-2 border rounded-md"
            required
          />
        </div>

        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">Total Price:</label>
          <input
            type="number"
            name="total_price"
            value={planFormData.total_price}
            onChange={handlePlanChange}
            className="block w-full border border-gray-300 p-2 rounded"
            readOnly
          />
        </div>
      </div>

      <div className="w-full mb-4 flex justify-center">
        <button
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
          type="submit"
        >
          Submit
        </button>
        <button
          className="btn bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          onClick={downloadExcel}
        >
          Download Excel
        </button>
        <button
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
          type="button"
          onClick={handleNavigateToGetallDetails}
        >
          Plan Details
        </button>
      </div>
    </form>
  );
};

export default CreatePlan;
