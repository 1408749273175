import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Homepage() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleButtonClick = () => {
    if (selectedOption) {
      setShowWelcomeMessage(true);

      // Conditional navigation based on the selected option
      setTimeout(() => {
        if (selectedOption === 'GigHub') {
          navigate('/Dashboard');
        } else if (selectedOption === 'Pentagram') {
          navigate('/Dashboard');
        } 
        else if (selectedOption === 'AirHub' ) {
          navigate('/Dashboard');
        } 
        else if (selectedOption === 'ClayTechPTD' ) {
          navigate('/Dashboard');
        } 
        else if (selectedOption === 'ClayTechLLP' ) {
          navigate('/Dashboard');
        } 
        else {
          // Add more conditions as needed for other options
          // navigate('/otherPage');
        }
      }, 2000); // Adjust the delay as needed
    } else {
      alert("Please select an option before clicking the button.");
    }
  };

  return (
    <div className="container mx-auto p-8 bg-gray-100 rounded-lg shadow-md">

        <h1 className="text-3xl font-bold mb-4">Select an Option</h1>
        
        <select
          className="w-full p-2 mb-4 border border-gray-300 rounded-md"
          onChange={handleOptionChange}
        >
          <option value="">Select Brand</option>
          <option value="GigHub">GigHub Systems Inc</option>
          <option value="Pentagram">Pentagram Technologies FZE</option>
          <option value="AirHub">AirHub System LTD</option>
          <option value="ClayTechPTD">CLAY TECHNOLOGIES PTD.LTD</option>
          <option value="ClayTechLLP">Clay Technolgy LLP</option>
        </select>
         <div className="w-full mb-4 flex justify-center">
         
          <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
          onClick={handleButtonClick}
        >
          Click me
        </button>
        </div>

        

        {showWelcomeMessage && (
          <p className="mt-4">Welcome to {selectedOption}!</p>
        )}
      </div>
  );
}

export default Homepage;
