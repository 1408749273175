import React, { useState, useEffect } from 'react';

const Getplan = () => {
  const [vendorData, setVendorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Track total pages
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const token = localStorage.getItem('token'); // Get token from localStorage

  const itemsPerPage = 5;

  useEffect(() => {
    if (token) {
      fetchData();
    } else {
      console.error('No token found in localStorage');
    }
  }, [token]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}Get_Plans?flag=1`, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in headers
        },
      });
      const responseData = await response.json();

      if (responseData.jsondata) {
        const data = JSON.parse(responseData.jsondata);
        if (Array.isArray(data)) {
          setVendorData(data);
          setTotalPages(Math.ceil(data.length / itemsPerPage));
        } else {
          console.error('Parsed data is not an array:', data);
        }
      } else {
        console.error('No data found in response');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = vendorData.slice(indexOfFirstItem, indexOfLastItem);

  const renderTableRows = () => {
    return currentItems.map((vendor, index) => (
      <tr key={index} className="border-b">
        <td className="py-5">{vendor.clients}</td>
        <td className="py-5">{vendor.plan_name}</td>
        <td className="py-5">{vendor.plan_count}</td>
        <td className="py-5">{vendor.buying_price}</td>
        <td className="py-5">{vendor.tax}</td>
        <td className="py-5">{vendor.conversion}</td>
        <td className="py-5">{vendor.currency_Name}</td>
        <td className="py-5">{vendor.Amount}</td>
        <td className="py-5">{vendor.taxAmount}</td>
        <td className="py-5">{vendor.Net_Amount}</td>
      </tr>
    ));
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
      <h1 className="text-3xl font-bold mb-4">Created Plan Details</h1>
      <div className="overflow-x-auto">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr>
              <th className="py-5">Clients</th>
              <th className="py-5">Data Plan</th>
              <th className="py-5">Counts</th>
              <th className="py-5">Buying Price</th>
              <th className="py-5">Tax</th>
              <th className="py-5">Conversion</th>
              <th className="py-5">Currency Type</th>
              <th className="py-5">Amount</th>
              <th className="py-5">Tax Amount</th>
              <th className="py-5">Net Amount</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Prev
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Getplan;
