import React from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { FcBullish } from 'react-icons/fc';
import { CiBank } from 'react-icons/ci';

import { DASHBOARD_SIDEBAR_BOTTOM_LINKS, DASHBOARD_SIDEBAR_LINKS } from '../lib/constants/navigation';
const linkClass =
  'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-lg'; // Increase the text-lg size for larger font

export default function Sidebar() {
  const role = localStorage.getItem('role');

  return (
    <div className="bg-neutral-900 w-60 p-3 flex flex-col md:w-26 md:flex-shrink-0">
      <div className="flex items-center gap-2 px-1 py-3">
        <FcBullish fontSize={24} />
        <span className="text-neutral-200 text-lg">Physical Sim(B2B)</span>
      </div>
      <div className="py-8 flex flex-1 flex-col gap-0.5">
        {DASHBOARD_SIDEBAR_LINKS.map((link) => (
          <SidebarLink key={link.key} link={link} />
        ))}
          {role === '1' && (
          <SidebarLink
            key="Insert Bank"
            link={{
              key: 'Insert Bank',
              label: 'Insert Bank',
              path: '/InsertBankform',
              icon: <CiBank style={{ color: 'white' }} />
            }}
          />
        )}
      </div>
      <div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
        {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((link) => (
          <SidebarLink key={link.key} link={link} />
        ))}
      </div>
    </div>
  );
}

function SidebarLink({ link }) {
  const { pathname } = useLocation();

  const handleClick = () => {
    if (link.key === 'Logout') {
      handleLogout(); // Call the logout function if the logout link is clicked
    }
  };

  return (
    <Link
      to={link.path}
      className={classNames(
        pathname === link.path ? 'bg-neutral-900 text-white' : 'text-neutral-400',
        linkClass
      )}
      onClick={handleClick}
    >
      <span className="text-xl">{link.icon}</span>
      {link.label}
    </Link>
  );
}

const handleLogout = () => {
  // Implement your logout logic here, such as clearing session/local storage, etc.
  // For example, clearing token and expiration time from localStorage
  localStorage.removeItem('token');
  localStorage.removeItem('expirationTime');

  // Redirect the user to the login page or any other appropriate destination
  window.location.href = '/'; // Change '/' to the appropriate logout destination
};
