import React, { useState, useEffect } from 'react';

const TestInvoice4 = () => {
  const [plans, setPlans] = useState([]);
  const [selectedPid, setSelectedPid] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [tableRows, setTableRows] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [brands, setBrands] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [vendors, setVendors] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState('');
  const [banks, setBanks] = useState([]); // New state for banks
  const [selectedBankId, setSelectedBankId] = useState(''); // New state for selected bank
  const [count, setCount] = useState(0); // Added state for count



  const [customerName, setCustomerName] = useState('');
  const [orderNumber, setOrderNumber] = useState('');
  const [dateInvoice, setDateInvoice] = useState(''); // You may need to handle date input separately
  const [dateBilling, setDateBilling] = useState(''); // You may need to handle date input separately
  const [items, setItems] = useState([]);
  const [description, setDescription] = useState('');
  const [notes, setNotes] = useState('');
  const [unitPrice, setUnitPrice] = useState('');
  const [financeYear, setFinanceYear] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);










  // Function to check if all required selections are made
  const areAllSelectionsMade = () => {
    return selectedPid !== '' && selectedClientId !== '' && selectedBrandId !== '' && selectedVendorId !== '';
  };

  const fetchAllPlans = () => {
    // Fetch plans from the API
    fetch('https://demo.claytelecom.com/api/Authentication/Get_all_Plans')
      .then(response => response.json())
      .then(data => {
        try {
          const jsonDataArray = JSON.parse(data.jsondata);
          if (Array.isArray(jsonDataArray)) {
            setPlans(jsonDataArray);
          } else {
            console.error('Invalid jsondata structure:', jsonDataArray);
          }
        } catch (error) {
          console.error('Error parsing jsondata:', error);
        }
      })
      .catch(error => console.error('Error fetching plans:', error));
  };

  const fetchClients = () => {
    // Fetch client details for dropdown
    fetch('https://demo.claytelecom.com/api/Authentication/Get_clientdetails?flag=1')
      .then(response => response.json())
      .then(data => {

        // Check if data is an object and contains a 'jsondata' property
        if (typeof data === 'object' && data.jsondata) {
          const jsonDataArray = JSON.parse(data.jsondata);
          if (Array.isArray(jsonDataArray)) {
            setClients(jsonDataArray);
          } else {
            console.error('Invalid jsondata structure in clients:', jsonDataArray);
          }
        } else {
          console.error('Invalid clients data structure:', data);
        }
      })
      .catch(error => console.error('Error fetching client details:', error));
  };

  const fetchBrands = () => {
    // Fetch brand details for dropdown
    fetch('https://demo.claytelecom.com/api/Authentication/Get_brand')
      .then(response => response.json())
      .then(data => {

        // Check if data is an object and contains a 'jsondata' property
        if (typeof data === 'object' && data.jsondata) {
          const jsonDataArray = JSON.parse(data.jsondata);
          if (Array.isArray(jsonDataArray)) {
            setBrands(jsonDataArray);
          } else {
            console.error('Invalid jsondata structure in brands:', jsonDataArray);
          }
        } else {
          console.error('Invalid brands data structure:', data);
        }
      })
      .catch(error => console.error('Error fetching brand details:', error));
  };

  const fetchVendors = () => {
    // Fetch vendor details for dropdown
    fetch('https://demo.claytelecom.com/api/Authentication/Getvendordetail?flag=1')
      .then(response => response.json())
      .then(data => {

        // Check if data is an object and contains a 'jsondata' property
        if (typeof data === 'object' && data.jsondata) {
          const jsonDataArray = JSON.parse(data.jsondata);
          if (Array.isArray(jsonDataArray)) {
            setVendors(jsonDataArray);
          } else {
            console.error('Invalid jsondata structure in vendors:', jsonDataArray);
          }
        } else {
          console.error('Invalid vendors data structure:', data);
        }
      })
      .catch(error => console.error('Error fetching vendor details:', error));
  };
  const fetchBanksByBrand = async (brandId) => {
    try {
      const response = await fetch(`https://demo.claytelecom.com/api/Authentication/Get_bank_byid?brandid=${brandId}`);
      const data = await response.json();
      if (data.isSuccess) {
        const jsonDataArray = JSON.parse(data.jsondata);
        if (Array.isArray(jsonDataArray)) {
          setBanks(jsonDataArray);
        } else {
          console.error('Invalid jsondata structure in banks:', jsonDataArray);
        }
      } else {
        console.error('Error in API response:', data.message);
      }
    } catch (error) {
      console.error('Error fetching banks:', error);
    }
  };
  useEffect(() => {
    fetchAllPlans();
    fetchClients();
    fetchBrands();
    fetchVendors();
    fetchBanksByBrand();
  }, []);

  const handlePidChange = (event) => {
    const selectedId = event.target.value;
    setSelectedPid(selectedId);

    // Find the selected plan based on p_id
    const selectedPlan = plans.find((plan) => plan.p_id === parseInt(selectedId));
    setSelectedPlan(selectedPlan);

    // Update the items array with the selected plan's name
    setItems((prevItems) => [...prevItems, selectedPlan?.plan_name]);
  };

  const handleClientChange = (event) => {
    const selectedClientId = event.target.value;
    setSelectedClientId(selectedClientId);
    // Additional logic for handling selected client, if needed
  };

  const handleBrandChange = (event) => {
    const selectedBrandId = event.target.value;
    setSelectedBrandId(selectedBrandId);
    // Fetch banks associated with the selected brand
    fetchBanksByBrand(selectedBrandId);
    // Additional logic for handling selected brand, if needed
  };

  const handleVendorChange = (event) => {
    const selectedVendorId = event.target.value;
    setSelectedVendorId(selectedVendorId);
    // Additional logic for handling selected vendor, if needed
  };
  const calculateTotalAmount = () => {
    const total = tableRows.reduce((acc, row) => acc + row.amount, 0);
    setTotalAmount(total);
  };

  const handleAddRow = async () => {
    if (selectedPlan && areAllSelectionsMade()) {
      // Fetch count from the API
      const apiUrl = `https://demo.claytelecom.com/api/Authentication/Get_inventory_stock_value_notbilled?client_id=${selectedClientId}&planname=${selectedPid}&v_id=${selectedVendorId}&brand_id=${selectedBrandId}`;

      try {
        const response = await fetch(apiUrl);
        const data = await response.json();

        console.log('API Response (Inventory):', data);
        calculateTotalAmount();

        if (data.isSuccess) {
          const countValue = data.jsondata ? JSON.parse(data.jsondata)[0]['COUNT(Id)'] : 0;
          setCount(countValue);

          // Add the selected plan along with the count and amount to tableRows
          setTableRows(prevRows => [
            ...prevRows,
            { ...selectedPlan, count: countValue, amount: 0 },
          ]);

          // Set the 'item' field to the value of 'plan_name' in the newly added row
          setItems((prevItems) => [...prevItems, selectedPlan?.plan_name]);
        } else {
          console.error('Error in API response:', data.message);
        }
      } catch (error) {
        console.error('Error fetching inventory data:', error);
      }
    }
  };


  const handleRemoveRow = (index) => {
    setTableRows(prevRows => prevRows.filter((_, i) => i !== index));
    calculateTotalAmount();

  };


  const handleSubmit = async () => {
    if (areAllSelectionsMade() && selectedPlan && tableRows.length > 0) {
      const apiUrl = 'https://demo.claytelecom.com/api/Authentication/insert_invoice';

      const invoiceData = {
        flag: 1,
        customer_name: customerName,
        order_no: orderNumber,
        date_invoice: dateInvoice, // You may need to handle date input separately
        date_billing: dateBilling, // You may need to handle date input separately
        item: items.join(', '), // Update this line
        qty: tableRows.reduce((total, row) => total + row.count, 0), // Sum of counts from tableRows
        amount: tableRows.reduce((total, row) => total + row.amount, 0).toString(), // Sum of amounts from tableRows
        client_id: selectedClientId,
        brand_id: selectedBrandId,
        description: description,
        notes: notes,
        bank_id: selectedBankId,
        unit_price: totalAmount.toString(),  // Update unit_price with totalAmount
        bank_fee: tableRows.reduce((total, row) => total + parseFloat(row.bank_fee || 0), 0).toString(), // Sum of bank_fee from tableRows
        financeyear: financeYear,
        v_id: selectedVendorId,
        plan_id: selectedPid,
      };

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(invoiceData),
        });

        const data = await response.json();

        if (data.isSuccess) {
          console.log('Invoice submitted successfully:', data.message);
          // Reset form fields or perform any other necessary actions
          alert('Invoice submitted successfully!');

        } else {
          console.error('Error submitting invoice:', data.message);
          alert(`Error submitting invoice: ${data.message}`);

        }
      } catch (error) {
        console.error('Error submitting invoice:', error);
        alert('Error submitting invoice. Please try again.');

      }
    }
  };









  const handleCalculate = (index) => {
    // Get the selected row
    const selectedRow = tableRows[index];

    // Get the user-entered bank_fee value
    const bankFee = parseFloat(document.getElementById(`bankFee_${index}`).value);

    // Calculate the amount based on buying_price, count, and bank_fee
    const amount = selectedRow.buying_price * selectedRow.count + bankFee;

    // Update the tableRows with the calculated amount
    setTableRows(prevRows => {
      const updatedRows = [...prevRows];
      updatedRows[index] = { ...selectedRow, amount: amount };
      return updatedRows;
    });
  };


  return (
    <>
      <label className="block text-sm font-semibold mb-2" htmlFor="brandSelect">Select Brand:</label>
      <select id="brandSelect" onChange={handleBrandChange}
        className="w-full p-2 border border-gray-300 rounded"
        value={selectedBrandId}>
        <option value="" disabled>Select a Brand</option>
        {Array.isArray(brands) &&
          brands.map(brand => (
            <option key={brand.brand_id} value={brand.brand_id}>
              {brand.brand_name}
            </option>
          ))}
      </select>
      <label className="block text-sm font-semibold mb-2" htmlFor="bankSelect">Select Bank:</label>
      <select id="bankSelect" value={selectedBankId}
        className="w-full p-2 border border-gray-300 rounded"

        onChange={(e) => setSelectedBankId(e.target.value)}>
        <option value="" disabled>Select a Bank</option>
        {Array.isArray(banks) &&
          banks.map((bank) => (
            <option key={bank.bank_id} value={bank.bank_id}>
              {bank.bank_name}
            </option>
          ))}
      </select>
      <label className="block text-sm font-semibold mb-2" htmlFor="vendorSelect">Select Vendor:</label>
      <select id="vendorSelect"
        className="w-full p-2 border border-gray-300 rounded"

        onChange={handleVendorChange} value={selectedVendorId}>
        <option value="" disabled>Select a Vendor</option>
        {Array.isArray(vendors) &&
          vendors.map(vendor => (
            <option key={vendor.v_id} value={vendor.v_id}>
              {vendor.name}
            </option>
          ))}
      </select>
      <label className="block text-sm font-semibold mb-2" htmlFor="clientSelect">Select Client:</label>
      <select id="clientSelect"
        className="w-full p-2 border border-gray-300 rounded"

        onChange={handleClientChange} value={selectedClientId}>
        <option value="" disabled>Select a Client</option>
        {/* Check if clients is an array before mapping over it */}
        {Array.isArray(clients) &&
          clients.map(client => (
            <option key={client.c_id} value={client.c_id}>
              {client.name}
            </option>
          ))}
      </select>
      <label className="block text-sm font-semibold mb-2" htmlFor="pidSelect">Select Plan:</label>
      <select id="pidSelect"
        className="w-full p-2 border border-gray-300 rounded"

        onChange={handlePidChange} value={selectedPid}>
        <option value="" disabled>Select a Plan</option>
        {plans.map(plan => (
          <option key={plan.p_id} value={plan.p_id}>
            {plan.plan_name}
          </option>
        ))}
      </select>

      <button onClick={handleAddRow} disabled={!areAllSelectionsMade() || !selectedPlan}>
        Add Row
      </button>

      <br />

      <br />
      {tableRows.length > 0 && (
        <div>
          <h2>Selected Plan Details</h2>
          <table>
            <thead>
              <tr>
                <th>Plan Name</th>
                <th>Buying Price</th>
                <th>Count</th>
                <th>Bank_fee</th>
                <td>Calculate</td>
                <td>Amount</td>
                <th>Action</th>

                {/* Add other columns as needed */}
              </tr>
            </thead>
            <tbody>
              {tableRows.map((row, index) => (
                <tr key={index}>
                  <td>{row.plan_name}</td>
                  <td>{row.buying_price}</td>
                  <td>{row.count}</td>
                  <td>
                    <input
                      type='number'
                      id={`bankFee_${index}`}
                      placeholder='Enter Bank Fee'
                    />
                  </td>
                  <td>
                    Amount<input type='number' value={row.amount} readOnly />
                  </td>
                  <td>
                    <button onClick={() => handleCalculate(index)}>
                      Calculate
                    </button>
                  </td>
                  <td>
                    <button onClick={() => handleRemoveRow(index)}>
                      Remove
                    </button>
                  </td>
                 
                  


                  {/* Add other cells as needed */}
                </tr>
              ))}
            </tbody>
            <div className="flex flex-wrap -mx-2">
            <button onClick={calculateTotalAmount}>
                    Calculate Total Amount
                  </button>
                   Total Amount <input type="text" id="item" value={totalAmount} readOnly />

                  </div>
          </table>

        </div>
      )}
      <div className="container mx-auto mt-8 p-4 bg-zinc-200	 rounded-lg">


        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
            <label htmlFor="itemInput">Items:</label>
            <input type="text" id="item" value={items.join(', ')} readOnly />
          </div>
          {/* <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">

            <label htmlFor="customerNameInput">Customer Name:</label>
            <input
              type="text"
              id="customerNameInput"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </div> */}
          <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">

            <label htmlFor="descriptionInput">description Number:</label>
            <input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">

            <label htmlFor="orderNumberInput">Order Number:</label>
            <input
              type="text"
              id="orderNumberInput"
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">

            <label htmlFor="notesInput">notes</label>
            <input
              type="text"
              id="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">

            <label htmlFor="dateInvoiceInput">Date Invoice:</label>
            <input
              type="date"
              id="dateInvoiceInput"
              value={dateInvoice}
              onChange={(e) => setDateInvoice(e.target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">

            <label htmlFor="dateBillingInput"> Billing Month:</label>
            <input
              type="month"
              id="dateBillingInput"
              value={dateBilling}
              onChange={(e) => setDateBilling(e.target.value)}
            />
          </div>

          <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">Finacial Year</label>
            <select
              id="financeyear"
              name="financeyear"
              onChange={(e) => setFinanceYear(e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2"
              required  >
              <option value="23-24">23-24</option>
              <option value="24-25">24-25</option>
              <option value="25-26">25-26</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>

            </select>
          </div>


        </div>



      </div>


      <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
        <button onClick={handleSubmit} disabled={!areAllSelectionsMade() || !selectedPlan || tableRows.length === 0}>
          Submit Invoice
        </button>


      </div>
      </>


  );
};

export default TestInvoice4;
