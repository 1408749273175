import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';

function Dashboard() {
  useEffect(() => {
    // Get the canvas element
    const canvas = document.getElementById("chartjs-7");
    
    // Check if a chart instance already exists
    if (canvas) {
      // If a chart instance exists, destroy it before rendering a new chart
      const existingChart = Chart.getChart(canvas);
      if (existingChart) {
        existingChart.destroy();
      }
    }

    // Render the new Chart.js chart
    const newChart = new Chart(canvas, {
      type: "bar",
      data: {
        labels: ["January", "February", "March", "April"],
        datasets: [
          {
            label: "Page Impressions",
            data: [10, 20, 30, 40],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.2)"
          },
          {
            label: "Adsense Clicks",
            data: [5, 15, 10, 30],
            type: "line",
            fill: false,
            borderColor: "rgb(54, 162, 235)"
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });

    // Cleanup function
    return () => {
      // Make sure to destroy the chart instance when the component unmounts
      newChart.destroy();
    };
  }, []); // Empty dependency array ensures useEffect runs only once after initial render

  return (
    <>
    <div className="flex flex-col md:flex-row">
    <div className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5">
      <div className="bg-gray-800 pt-3">
        <div className="rounded-tl-3xl bg-gradient-to-r from-blue-900 to-gray-800 p-4 shadow text-2xl text-white">
          <h3 className="font-bold pl-2">Admin Dashboard</h3>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 xl:w-1/3 p-6">
          <div className="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-green-600"><i className="fa fa-wallet fa-2x fa-inverse"></i></div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h5 className="font-bold uppercase text-gray-600">Invoice Amount</h5>
                <h3 className="font-bold text-3xl">$3249 <span className="text-green-500"><i className="fas fa-caret-up"></i></span></h3>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 xl:w-1/3 p-6">
          <div className="bg-gradient-to-b from-pink-200 to-pink-100 border-b-4 border-pink-500 rounded-lg shadow-xl p-5">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-pink-600"><i className="fas fa-users fa-2x fa-inverse"></i></div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h5 className="font-bold uppercase text-gray-600">Total Client</h5>
                <h3 className="font-bold text-3xl">249 <span className="text-pink-500"><i className="fas fa-exchange-alt"></i></span></h3>
              </div>
            </div>
          </div>
        </div>
        
        <div className="w-full md:w-1/2 xl:w-1/3 p-6">
          <div className="bg-gradient-to-b from-yellow-200 to-yellow-100 border-b-4 border-yellow-600 rounded-lg shadow-xl p-5">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded-full p-5 bg-yellow-600"><i className="fas fa-user-plus fa-2x fa-inverse"></i></div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h5 className="font-bold uppercase text-gray-600">Total Vendor </h5>
                <h3 className="font-bold text-3xl">2 <span className="text-yellow-600"><i className="fas fa-caret-up"></i></span></h3>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white border-transparent rounded-lg shadow-xl flex">
  <div className="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2">
  </div>
  <div className="p-5 flex">
    {/* First Chart */}
    <div style={{ flex: 1 }}>
    <h5 className="font-bold uppercase text-gray-600">Graph</h5>

    <canvas id="chartjs-7" width="400" height="400"></canvas>
    </div>
    {/* Second Chart */}
    <div style={{ flex: 1 }}>
    <div class="w-full md:w-3/2 xl:w-3/3 p-6">
                    <div class="bg-white border-transparent rounded-lg shadow-xl">
                        <div class="bg-gradient-to-b from-gray-300 to-gray-100 uppercase text-gray-800 border-b-2 border-gray-300 rounded-tl-lg rounded-tr-lg p-2">
                            <h5 class="font-bold uppercase text-gray-600">                            <h5 class="font-bold uppercase text-gray-600">Top Selling Plans</h5>
</h5>
                        </div>
                        <div class="p-5">
                            <table class="w-full p-5 text-gray-700">
                                <thead>
                                    <tr>
                                        <th class="text-left text-blue-900">Plan Name</th>
                                        <th class="text-left text-blue-900">Buy</th>
                                        <th class="text-left text-blue-900">Sell</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>10GB</td>
                                        <td>100</td>
                                        <td>250</td>
                                    </tr>
                                    <tr>
                                        <td>20Gb</td>
                                        <td>200</td>
                                        <td>300</td>
                                    </tr>
                                    <tr>
                                        <td>30GB</td>
                                        <td>400</td>
                                        <td>450</td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                    </div>
                </div>
                </div>
  </div>
</div>
      </div>
    </div>
  </div>





<div className="flex flex-col md:flex-row">
<div className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5">
 
  <div className="flex flex-wrap">
    <div className="w-full md:w-1/2 xl:w-1/3 p-6">
      <div className="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5">
        <div className="flex flex-row items-center">
          <div className="flex-shrink pr-4">
            <div className="rounded-full p-5 bg-green-600"><i className="fa fa-wallet fa-2x fa-inverse"></i></div>
          </div>
          <div className="flex-1 text-right md:text-center">
            <h5 className="font-bold uppercase text-gray-600">Total Plans</h5>
            <h3 className="font-bold text-3xl">50 <span className="text-green-500"><i className="fas fa-caret-up"></i></span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-1/2 xl:w-1/3 p-6">
      <div className="bg-gradient-to-b from-pink-200 to-pink-100 border-b-4 border-pink-500 rounded-lg shadow-xl p-5">
        <div className="flex flex-row items-center">
          <div className="flex-shrink pr-4">
            <div className="rounded-full p-5 bg-pink-600"><i className="fas fa-users fa-2x fa-inverse"></i></div>
          </div>
          <div className="flex-1 text-right md:text-center">
            <h5 className="font-bold uppercase text-gray-600">Total Users</h5>
            <h3 className="font-bold text-3xl">49 <span className="text-pink-500"><i className="fas fa-exchange-alt"></i></span></h3>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-1/2 xl:w-1/3 p-6">
      <div className="bg-gradient-to-b from-yellow-200 to-yellow-100 border-b-4 border-yellow-600 rounded-lg shadow-xl p-5">
        <div className="flex flex-row items-center">
          <div className="flex-shrink pr-4">
            <div className="rounded-full p-5 bg-yellow-600"><i className="fas fa-user-plus fa-2x fa-inverse"></i></div>
          </div>
          <div className="flex-1 text-right md:text-center">
            <h5 className="font-bold uppercase text-gray-600">New Users</h5>
            <h3 className="font-bold text-3xl">11 <span className="text-yellow-600"><i className="fas fa-caret-up"></i></span></h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</>





  
  );
}

export default Dashboard;
