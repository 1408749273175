import React, { useState, useEffect } from 'react';

const YourComponent = () => {
  const [salesDetails, setSalesDetails] = useState(null);
  const token = localStorage.getItem('token'); // Retrieve the token from localStorage

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://demo.claytelecom.com/api/Authentication/Get_sales_details?flag=1", {
          headers: {
            'Authorization': `Bearer ${token}`, // Include token in headers
          },
        });
        const data = await response.json();

        if (data.isSuccess) {
          // Assuming jsondata is always an array with one object
          const salesData = JSON.parse(data.jsondata)[0];
          setSalesDetails(salesData);
        } else {
          console.error("API request failed:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]); // The dependency array now includes token to handle cases when it might change

  return (
    <div>
      {salesDetails ? (
        <div>
          <p>Total Outstanding Receivable: {salesDetails.total_outstanding_receivable}</p>
          <p>Due in 30 Days: {salesDetails.due_in_30_days}</p>
          <p>Overdue More Than 30 Days: {salesDetails.overdue_more_than_30_days}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default YourComponent;
