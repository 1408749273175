import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreateVendorForm = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const token = localStorage.getItem('token'); // Get token from localStorage

  const [formData, setFormData] = useState({
    flag: 1,
    userName: '',
    lastname: '',
    email: '',
    mobileno: '',
    phoneno: '',
    website: '',
    companyname: '',
    contactno: '',
    zipcode: '',
    fax: '',
    phone_no: '',
    address:'',
  });
  const handleNavigateToDetails = () => {
    navigate('/Vendordetails');
  };

  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleToggleDetails = () => {
    setShowAdditionalDetails(!showAdditionalDetails);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if required fields are empty
    if (!formData.userName || !formData.email || !formData.mobileno || !formData.phoneno) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      // Assuming you use the Fetch API to make the API call
      // const response = await fetch('https://demo.claytelecom.com/api/Authentication/CreateVendor', {
        const response = await fetch(`${apiUrl}CreateVendor`, { // Use apiUrl here

        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include token in headers

        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      if (response.ok) {
        // Handle success, show a success message
        alert('Vendor created successfully. Thank you!');
        // You can also reset the form after successful submission if needed
        setFormData({
          flag: 1,
          userName: '',
          lastname: '',
          email: '',
          website: '',
          mobileno: '',
          phoneno: '',
          companyname: '',
          contactno: '',
          zipcode: '',
          fax: '',
          phone_no: '',
          address:'',
        });
        // Close additional details if open
        setShowAdditionalDetails(false);
        navigate('/Vendordetails');
      } else {
        // Handle error, show an error message
        alert('Failed to create vendor. Please check your input and try again.');
        console.error('Failed to create vendor:', responseData);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
      <h1 className="text-center text-3xl font-bold mb-4">Vendor Add</h1>

      <div className="flex flex-wrap -mx-2">
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
            First Name:
            <input
              type="text"
              name="userName"
              value={formData.userName}
              onChange={handleChange}
              className="block w-full mt-1 p-2 border rounded-md"
            />
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
            Last Name:
            <input
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              className="block w-full mt-1 p-2 border rounded-md"
            />
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
          Email:
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="block w-full mt-1 p-2 border rounded-md"
            />
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
          Website:
            <input
              type="text"
              name="website"
              value={formData.website}
              onChange={handleChange}
              className="block w-full mt-1 p-2 border rounded-md"
            />
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
            Mobile Number:
            <input
              type="number"
              name="mobileno"
              value={formData.mobileno}
              onChange={handleChange}
              className="block w-full mt-1 p-2 border rounded-md"
            />
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
          Country:
            <input
              type="text"
              name="phoneno"
              value={formData.phoneno}
              onChange={handleChange}
              className="block w-full mt-1 p-2 border rounded-md"
            />
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
          Company Name::
            <input
              type="text"
              name="companyname"
              value={formData.companyname}
              onChange={handleChange}
              
              className="block w-full mt-1 p-2 border rounded-md"
            />
          </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
          Address:
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              
              className="block w-full mt-1 p-2 border rounded-md"
            />
          </label>
        </div>
        {/* Add similar styling for other form fields */}

        <div className="w-full mb-4 flex justify-center">

          <button
            type="button"
            onClick={handleToggleDetails}
            style={{ borderRadius: '20px', padding: '10px', alignItems: 'center',justifyContent:'center' }} // Added styles

            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            {showAdditionalDetails ? 'Hide Additional Details' : 'Add More Details'}
          </button>
        </div>

        {showAdditionalDetails && (
          <>
           
            <div className="w-full mb-4">
              <label className="block mb-2 text-gray-700">
                Zip Code:
                <input
                  type="number"
                  name="zipcode"
                  value={formData.zipcode}
                  onChange={handleChange}
                  className="block w-full mt-1 p-2 border rounded-md"
                />
              </label>
            </div>
              <div className="w-full mb-4">
              <label className="block mb-2 text-gray-700">
                 Fax:
                <input
                  type="number"
                  name="fax"
                  value={formData.fax}
                  onChange={handleChange}
                  className="block w-full mt-1 p-2 border rounded-md"
                />
              </label>
            </div>
            <div className="w-full mb-4">
              <label className="block mb-2 text-gray-700">
                Phone Number:
                <input
                  type="number"
                  name="phone_no"
                  value={formData.phone_no}
                  onChange={handleChange}
                  className="block w-full mt-1 p-2 border rounded-md"
                />
              </label>
            </div>
            {/* Add similar styling for other additional form fields */}
          </>
        )}

<div className="w-full mb-4 flex justify-center">

          <button
            type="submit"
            style={{ borderRadius: '20px', padding: '10px', alignItems: 'center',justifyContent:'center' }} // Added styles
            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4"
          >
            Submit
          </button>

          <button
            type="button"
            onClick={handleNavigateToDetails}
            style={{ borderRadius: '20px', padding: '10px', alignItems: 'center',justifyContent:'center' }} // Added styles
            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Vendor Details
          </button>
        </div>


      </div>
    </form>
  );
};

export default CreateVendorForm;
