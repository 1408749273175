import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import GighubInvoice from './email/Gighub';

const Invoice = () => {
    const [clientIds, setClientIds] = useState([]);
    const [brandIds, setbrandIds] = useState([]);
    const [bankIds, setbankIds] = useState([]);
    const navigate = useNavigate();
    const [selectedClientId, setSelectedClientId] = useState('');
    const [selectedBrandId, setSelectedBrandId] = useState('');
    const [selectedBankId, setSelectedBankId] = useState('');
    const [planOptions, setPlanOptions] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [vendorNames, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState('');
    const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
    const [rowCount, setRowCount] = useState(1); // Track the number of rows
    const [selectedPlans, setSelectedPlans] = useState([]);

    const unit_price_val = useRef(null);
    const qty_val = useRef(null);
    const [total_price, setTotalPrice] = useState(null);
    const [tableRows, setTableRows] = useState([
        {
            id: 1,
            item: '',
            qty: '',
            unitPrice: '',
            bankFee: '',
            amount: '',
            selectedPlan: '' // Add selectedPlan property for each row
        }
    ]);

    const [invoiceData, setInvoiceData] = useState({
        flag: 1,
        customer_name: '',
        order_no: '',
        date_invoice: '',
        date_billing: '',
        item: '',
        qty: '',
        amount: '',
        client_id: '',
        brand_id: '',
        description: '',
        notes: '',
        bank_id: '',
        unit_price: '',
        bank_fee: '',
        financeyear: '',
        plan_id: '',
        v_id: '',
        invoice_name: '',

    });

    useEffect(() => {
        fetchclients();
        fetchBrands();
        fetchAllPlans();
        fetchVendors();

    }, []);
    const addRow = () => {
        setRowCount(rowCount + 1);



        const newRow = {
            id: rowCount + 1,
            item: '',
            qty: '',
            unitPrice: '',
            bankFee: '',
            amount: ''
        };

        setTableRows(prevRows => [...prevRows, newRow]);
    };

    const handleTableRowChange = (index, field, value) => {
        setTableRows(prevRows => {
            const updatedRows = [...prevRows];
            updatedRows[index][field] = value;
            return updatedRows;
        });
    };









    const InvoicDetails = () => {
        navigate('/Invoicedetails');
    };

    const fetchAllPlans = async () => {
        try {
            const response = await fetch('https://demo.claytelecom.com/api/Authentication/Get_all_Plans');
            const responseData = await response.json();
            const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

            if (Array.isArray(data)) {
                setPlanOptions(data.map((plan) => ({ value: plan.p_id, label: plan.plan_name })));
            } else {
                console.error('Parsed data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching plans:', error);
        }

    };

    const fetchclients = async () => {
        try {
            const response = await fetch('https://demo.claytelecom.com/api/Authentication/Get_clientdetails?flag=1');
            const responseData = await response.json();
            const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

            if (Array.isArray(data)) {
                setClientIds(data.map((vendor) => ({ value: vendor.c_id, label: vendor.name })));
            } else {
                console.error('Parsed data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchBrands = async () => {
        try {
            const response = await fetch('https://demo.claytelecom.com/api/Authentication/Get_brand');
            const responseData = await response.json();
            const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

            if (Array.isArray(data)) {
                setbrandIds(data.map((brand) => ({ value: brand.brand_id, label: brand.brand_name })));
            } else {
                console.error('Parsed data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching brand data:', error);
        }
    };

    const GetBanks = async (prevBrandId) => {
        try {
            const response = await fetch(`https://demo.claytelecom.com/api/Authentication/Get_bank_byid?brandid=${prevBrandId}`);
            const responseData = await response.json();
            const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

            if (Array.isArray(data)) {
                setbankIds(data.map((bank) => ({ value: bank.bank_id, label: bank.bank_name })));
            } else {
                console.error('Parsed data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching banks:', error);
        }
    };
    const handlePlanChange = async (event, rowIndex) => {
        const newSelectedPlans = [...selectedPlans];
        newSelectedPlans[rowIndex] = event.target.value;
        setSelectedPlans(newSelectedPlans);

        // Update the selectedPlan property for the specific row
        setTableRows(prevRows =>
            prevRows.map((row, index) =>
                index === rowIndex ? { ...row, selectedPlan: event.target.value } : row
            )
        );
        try {
            const response = await fetch(`https://demo.claytelecom.com/api/Authentication/Get_inventory_stock_value_notbilled?client_id=${selectedClientId}&planname=${event.target.value}&v_id=${selectedVendor}&brand_id=${selectedBrandId}`);
            const responseData = await response.json();

            const qtyValue = responseData.jsondata ? JSON.parse(responseData.jsondata)[0]['COUNT(Id)'] : 0;
            setInvoiceData(prevData => ({
                ...prevData,
                qty: qtyValue !== undefined ? qtyValue.toString() : '',
            }));

            // Fetch and set plan details
            const selectedPlanDetailsResponse = await fetch(`https://demo.claytelecom.com/api/Authentication/Get_all_Plans`);
            const selectedPlanDetailsData = await selectedPlanDetailsResponse.json();
            const selectedPlanDetailsArray = selectedPlanDetailsData.jsondata ? JSON.parse(selectedPlanDetailsData.jsondata) : [];
            const selectedPlanDetail = selectedPlanDetailsArray.find(plan => plan.p_id === parseInt(event.target.value));

            setSelectedPlanDetails(selectedPlanDetail);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchVendors = async () => {
        try {
            const response = await fetch(`https://demo.claytelecom.com/api/Authentication/Getvendordetail?flag=1`);

            const responseData = await response.json();
            // Check if jsondata is present and parse it into an array
            const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

            // Check if data is an array before setting it
            if (Array.isArray(data)) {
                setVendors(data.map((vendor) => ({ value: vendor.v_id, label: vendor.name })));
            } else {
                console.error('Parsed data is not an array:', data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleClientIdChange = async (event) => {
        const selectedId = event.target.value;
        const name = event.target.name;

        setSelectedClientId(selectedId);

        invoiceDataSave('customer_name', clientIds.find(item => item.value == selectedId).label);
        invoiceDataSave(name, selectedId);

        try {
            const response = await fetch(`https://demo.claytelecom.com/api/Authentication/Get_inventory_stock_value_notbilled?client_id=${selectedId}&planname=${selectedPlan}&v_id=${selectedVendor}&brand_id=${selectedBrandId}`);
            const responseData = await response.json();

            const qtyValue = responseData.jsondata ? JSON.parse(responseData.jsondata)[0]['COUNT(Id)'] : 0;
            setInvoiceData(prevData => ({
                ...prevData,
                qty: qtyValue !== undefined ? qtyValue.toString() : '',
            }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const handleBrandChange = async (event) => {
        const selectedId = event.target.value;
        const name = event.target.name;

        setSelectedBrandId(selectedId);

        setSelectedBrandId((prevBrandId) => {
            GetBanks(prevBrandId);
            return selectedId;
        });

        invoiceDataSave(name, selectedId);
    };

    const handleBankChange = (event) => {
        const selectedId = event.target.value;
        const name = event.target.name;

        setSelectedBankId(selectedId);
        invoiceDataSave(name, selectedId);
    };
    const invoiceDataSave = (name, value) => {
        setInvoiceData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleVendorChange = (event) => {
        const selectedId = event.target.value;
        setSelectedVendor(selectedId);
        invoiceDataSave('v_id', selectedId);

        // handle the changes when a vendor is selected
        // ...

        // You might want to set some state or perform some actions here
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setInvoiceData(prevData => ({
            ...prevData,
            [name]: value,
            client_id: selectedClientId,
        }));

        if (name === 'unit_price' || name === 'qty') {
            const calculatedAmount = parseFloat(value) * parseFloat(invoiceData.qty);
            setInvoiceData(prevData => ({
                ...prevData,
                amount: (name === 'unit_price' || name === 'qty') ? parseFloat(value) * parseFloat(prevData.qty) : prevData.amount,
            }));
        }
    };









    const handleInsertInvoice = () => {
        debugger;
        GighubInvoice(invoiceData);

        axios.post('https://demo.claytelecom.com/api/Authentication/insert_invoice', invoiceData)
            .then(response => {
                console.log('Invoice inserted successfully', response.data);
                console.log(invoiceData);
                if (response.status === 200) {
                    GighubInvoice(invoiceData);
                    alert('Invoice inserted successfully');
                }
            })
            .catch(error => {
                console.error('Error inserting invoice', error);

                // Check if the error contains response data with details
                if (error.response && error.response.data) {
                    console.error('Error details:', error.response.data);
                    alert(`Error inserting invoice: ${error.response.data}`);
                } else {
                    alert('Error inserting invoice. Please try again.');
                }
            });
    };


    console.log('client', selectedClientId)
    console.log('plan', selectedPlan)
    console.log('brand', selectedBrandId)
    console.log('vendor', selectedVendor)
    return (
        <>
            <form className="container mx-auto mt-8 p-4 bg-zinc-50	 rounded-lg ">

                <h1 className="text-3xl font-bold mb-4">Invoice Create</h1>


                <label className="block text-sm font-semibold mb-2">Select Brand Name:</label>
                <select
                    value={selectedBrandId}
                    onChange={handleBrandChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    name='brand_id'
                >
                    <option value="0" >Select a Brand</option>
                    {brandIds.map((brand) => (

                        <option key={brand.value} value={brand.value}>
                            {brand.label}
                        </option>

                    ))}
                </select>

                {/* Select Banks >> */}
                <label className="block text-sm font-semibold mb-2">Select Bank:</label>
                <select
                    value={selectedBankId}
                    onChange={handleBankChange}
                    name='bank_id'
                    className="w-full p-2 border border-gray-300 rounded"
                >
                    <option value="" disabled>Select Bank Details</option>
                    {bankIds.map((bank) => (
                        <option key={bank.value} value={bank.value}>
                            {bank.label}
                        </option>
                    ))}
                </select>

                <label className="block text-sm font-semibold mb-2">Select Vendor:</label>

                <select
                    value={selectedVendor}
                    onChange={handleVendorChange}
                    name='v_id'
                    className="block w-full border border-gray-300 p-2 rounded"
                    required  // Make the dropdown required

                >
                    <option value="" disabled>Select a vendor</option>
                    {vendorNames.map((vendor) => (
                        <option key={vendor.value} value={vendor.value} name={vendor.label}>
                            {vendor.label}
                        </option>
                    ))}
                </select>


                <label className="block text-sm font-semibold mb-2">Select Client:</label>
                <select
                    value={selectedClientId}
                    onChange={handleClientIdChange}
                    className="w-full p-2 border border-gray-300 rounded"
                    name="client_id"
                >
                    <option value="" disabled>Select a Client</option>
                    {clientIds.map((vendor) => (
                        <option key={vendor.value} value={vendor.value} name={vendor.label}>
                            {vendor.label}

                        </option>
                    ))}
                </select>

                <br />

                <br />

                <div className="container mx-auto mt-8 p-4 bg-zinc-200	 rounded-lg">


                    <div className="flex flex-wrap -mx-2">

                        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
                            <label className="block mb-2 text-gray-700">Invoice Name</label>
                            <input type="text" id="invoice_name" name="invoice_name" placeholder="Invoice Name" onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
                            <label className="block mb-2 text-gray-700">Invoice Notes</label>
                            <input type="text" name="notes" placeholder="Notes" value={invoiceData.notes} onChange={handleInputChange} />
                        </div>

                        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
                            <label className="block mb-2 text-gray-700">Description </label>
                            <input type="text" name="description" placeholder="Description" value={invoiceData.description} onChange={handleInputChange} />

                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
                            <label className="block mb-2 text-gray-700">Order Number</label>
                            <input type="number" id="order_no" name="order_no" placeholder="Order Number" onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                required
                            />
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
                            <label className="block mb-2 text-gray-700">Invoice Date </label>
                            <input type="date" name="date_invoice" placeholder="date_invoice" onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                                required />
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
                            <label className="block mb-2 text-gray-700">Billing Month </label>
                            <input type="date" id="date_billing"
                                name="date_billing" placeholder="date_billing" onChange={handleInputChange} required />

                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
                            <label className="block mb-2 text-gray-700">Finacial Year</label>
                            <select
                                id="financeyear"
                                name="financeyear"
                                onChange={handleInputChange}
                                className="w-full border border-gray-300 rounded-md p-2"
                                required  >
                                <option value="23-24">23-24</option>
                                <option value="24-25">24-25</option>
                                <option value="25-26">25-26</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>

                            </select>
                        </div>

                    </div>


                    <div className="container mx-auto mt-8 p-6 bg-gray-100 rounded-lg">
                        <table>
                            <tbody>
                                {tableRows.map((row, index) => (
                                    <tr key={row.id}>
                                        <td>
                                            <label className="block text-sm font-semibold mb-2">Select Plan:</label>
                                            <select
                                                value={row.selectedPlan}
                                                onChange={(event) => handlePlanChange(event, index)}
                                                className="w-full p-2 border border-gray-300 rounded"
                                                name='plan_id'
                                            >
                                                <option value="" disabled>Select a Plan</option>
                                                {planOptions.map((plan) => (
                                                    <option key={plan.value} value={plan.value} name={plan.label}>
                                                        {plan.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td>
                                            {selectedPlanDetails && (
                                                <td className="py-2"><p> Selling Price{selectedPlanDetails.offered_name}</p></td>)}
                                        </td>
                                        {/* <td className="py-2">Item<input type="text" id="item" name="item" placeholder="Item" onChange={handleInputChange} required /></td> */}
                                        <td className="py-2">Qty<input ref={qty_val} type="text" name="qty" placeholder="QTY" value={invoiceData.qty === undefined ? '' : invoiceData.qty} onChange={handleInputChange} /></td>
                                        <td className="py-2">Selling Price<input ref={unit_price_val} type="number" name="unit_price" placeholder="Selling Price" value={invoiceData.unit_price} onChange={handleInputChange} /></td>
                                        <td className="py-2">Bank fee <input type="number" name="bank_fee" placeholder="Bank Fee" /></td>
                                        <td className="py-2">Amount <input type="number" name="amount" placeholder="Amount" onChange={handleInputChange} /></td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="w-full mb-4 flex justify-center">
                            <button
                                style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
                                className="btn bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="button"
                                onClick={addRow}
                            >
                                Add Row
                            </button>
                        </div>
                    </div>









                </div>
            </form>
            <br />

            <div className="w-full mb-4 flex justify-center">
                <button
                    style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
                    className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    onClick={handleInsertInvoice}>Insert Invoice
                </button>
                <button
                    type="button"
                    onClick={InvoicDetails}
                    style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }} // Added styles
                    className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Invoicedetails
                </button>

            </div>

        </>
    );
};


export default Invoice;

