import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

const Clientdetails = () => {
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  const [vendorData, setVendorData] = useState([]);
  const [filteredVendorData, setFilteredVendorData] = useState([]); // State to hold filtered vendor data
  const [currentPage, setCurrentPage] = useState(1);
  const [editingVendor, setEditingVendor] = useState(null); // State to hold the vendor being edited
  const [searchQuery, setSearchQuery] = useState(''); // State to hold the search query
  const itemsPerPage = 10;

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  useEffect(() => {
    // Filter vendor data when search query changes
    filterVendorData();
  }, [searchQuery, vendorData]);

  const fetchData = async () => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage

    if (!token) {
      console.error('Token not found in localStorage.');
      // Optionally, redirect the user to the login page or display an error message
      return;
    }

    try {
      const response = await fetch(`${apiUrl}Get_clientdetails?flag=1`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include the token in the Authorization header
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      // Check if jsondata is present and parse it into an array
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];
      // Check if data is an array before setting it
      if (Array.isArray(data)) {
        setVendorData(data);
        setFilteredVendorData(data); // Initialize filtered data with all vendor data
      } else {
        console.error('Parsed data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const filterVendorData = () => {
    const filteredData = vendorData.filter(
      (vendor) =>
        (vendor.name &&
          vendor.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (vendor.vendorname &&
          vendor.vendorname.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (vendor.lastname &&
          vendor.lastname.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (vendor.mobile_number &&
          vendor.mobile_number.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (vendor.company_name &&
          vendor.company_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (vendor.emailid &&
          vendor.emailid.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (vendor.address &&
          vendor.address.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (vendor.website &&
          vendor.website.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    setFilteredVendorData(filteredData);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredVendorData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleEdit = (vendor) => {
    // Set the editing vendor
    setEditingVendor(vendor);
  };

  const handleSave = async (updatedVendor) => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage

    if (!token) {
      console.error('Token not found in localStorage.');
      // Optionally, handle this case, e.g., redirect the user to the login page
      return;
    }

    try {
      // Make API call to edit client details
      const response = await fetch(
        'https://demo.claytelecom.com/api/Authentication/update_client',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Include the token in the Authorization header
          },
          body: JSON.stringify(updatedVendor)
        }
      );
      if (response.ok) {
        const updatedData = vendorData.map((vendor) =>
          vendor.c_id === updatedVendor.c_id ? updatedVendor : vendor
        );
        setVendorData(updatedData);
        setEditingVendor(null); // Reset editing state
      } else {
        console.error('Failed to edit client details:', response.statusText);
      }
    } catch (error) {
      console.error('Error editing client details:', error);
    }
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(currentItems); // Use currentItems instead of Clientdetails
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clientdetails');
    XLSX.writeFile(workbook, 'Clientdetails.xlsx');
  };

  const renderTableRows = () => {
    return currentItems.map((vendor, index) => (
      <tr key={index} className="border-b">
        <td className="py-2">{vendor.c_id}</td>
        <td className="py-2">{vendor.vendorname}</td>
        <td className="py-2">{vendor.name}</td>
        <td className="py-2">{vendor.lastname}</td>
        <td className="py-2">{vendor.mobile_number}</td>
        <td className="py-2">{vendor.company_name}</td>
        <td className="py-2">{vendor.emailid}</td>
        <td className="py-2">{vendor.address}</td>
        <td className="py-2">{vendor.website}</td>
        <td className="py-2">{vendor.contact_number}</td>

        <td className="py-2">{vendor.v_id}</td>

        <td className="py-2">
          <button onClick={() => handleEdit(vendor)}>Edit</button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
      <h1 className="text-3xl font-bold mb-4">Client Details</h1>

      <div className="flex items-center justify-between mt-4">
        <input
          type="search"
          className="p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-48"
          placeholder="Search by Name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={downloadExcel}>Download Excel</button>
      </div>

      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="py-2">c_id</th>
            <th className="py-2">Vendor Name</th>
            <th className="py-2">First Name</th>
            <th className="py-2">Lastname </th>
            <th className="py-2">Mobile Number</th>
            <th className="py-2">Company Name</th>
            <th className="py-2">Email</th>
            <th className="py-2">Address</th>
            <th className="py-2">Website</th>
            <th className="py-2">Country</th>

            <th className="py-2">Actions</th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Prev
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={indexOfLastItem >= filteredVendorData.length}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Clientdetails;
