import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart1 from '../images/illustration.svg';
import login from '../images/login-3.webp';

import '../css/Signup.css';

const UserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiry = () => {
      const expirationTime = localStorage.getItem('expirationTime');
      if (expirationTime) {
        const currentTime = new Date().getTime();
        if (currentTime > parseInt(expirationTime)) {
          // Token has expired
          alert('Token has expired.');
          localStorage.removeItem('token');
          localStorage.removeItem('role');
          localStorage.removeItem('expirationTime');
        }
      }
    };

    checkTokenExpiry();
  }, []);

  const Signuppage = () => {
    navigate('/Signup');
  };
  const handleLogin = async () => {
    try {
      const response = await fetch('https://demo.claytelecom.com/api/Authentication/UserLogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailid: email,
          password: password,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        if (data.token !== null) {
          const expirationTime = new Date().getTime() + 1 * 60 * 60 * 1000; // 1 hour
          localStorage.setItem('token', data.token);
          localStorage.setItem('role', data.data.role);
          localStorage.setItem('expirationTime', expirationTime);
          setMessage(`Login Successful. Token: ${data.token}`);
          console.log('Token:', data.token); // Log token to console
          navigate('/Homepage');
        } else {
          setMessage('Email or password is incorrect.');
        }
      } else {
        setMessage(`Login Failed. ${data.message}`);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setMessage('An error occurred during login.');
    }
  };
  

  return (
    <>
      <div className="signup-login-form-container" style={{marginTop:'20px',marginBottom:'20px' }} >
        <div className="image-container">
          <img src={Chart1} alt="Hello" />
        </div>
        
        <div className="form-container">
          <div>
          {/* <div className="image-container">
  <img src={login} alt="Hello" style={{ float: 'right', marginRight: '20px' }} />
</div>
 */}


          <h1 style={{ fontWeight: 'bold', color: 'black', fontSize: '24px' }}>Sign in to your account</h1>
<br/>
            <label className="block text-sm font-semibold mb-2">Email:</label>
            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div>
            <label className="block text-sm font-semibold mb-2">Password:</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className="w-full mb-4 flex justify-center">
            <button
              className="w-medium bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none"
              style={{ borderRadius: '20px', padding: '20px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => handleLogin()}
            >
              Login User
            </button>
          </div>
          <p>{message}</p>
          <br />
          <a href="https://gighubinc.com/" style={{ color: 'black' ,fontSize:'bold'}}>New to Gighub.In</a>
        </div>
      </div>
    </>
  );
};

export default UserLogin;
