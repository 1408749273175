import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function InsertBankForm() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token'); // Retrieve the token from localStorage

  const [brandId, setBrandId] = useState(0);
  const [bankName, setBankName] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [swiftCode, setSwiftCode] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [bankAddress, setBankAddress] = useState('');
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    fetchBrands();
  }, []);
  
  const fetchBrands = async () => {
    try {
      const response = await fetch('https://demo.claytelecom.com/api/Authentication/Get_brand', {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in headers
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch brands');
      }
      const data = await response.json();
      const parsedData = JSON.parse(data.jsondata);
      if (Array.isArray(parsedData)) {
        setBrands(parsedData);
      } else {
        throw new Error('Invalid response format for brands');
      }
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      flag: 1,
      brand_id: brandId,
      bank_name: bankName,
      bank_account_number: bankAccountNumber,
      swift_code: swiftCode,
      routing_number: routingNumber,
      bank_address: bankAddress
    };
  
    try {
      const response = await fetch('https://demo.claytelecom.com/api/Authentication/insert_bank', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include token in headers
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error('Failed to insert bank');
      }
      alert('Bank details successfully created.');
      navigate('/GetBank');
    } catch (error) {
      console.error('Error inserting bank:', error);
      alert('Failed to create bank details. Please try again.');
    }
  };
  
  const InvoicDetails = () => {
    navigate('/GetBank');
  };



  return (
    <>
    <form onSubmit={handleSubmit} className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg ">
    <h1 className="text-center text-3xl font-bold mb-6">Insert Bank</h1>

    <label className="block mb-4">
       Select Brand:
        <select 
        value={brandId} 
        onChange={(e) => setBrandId(e.target.value)}
        className="block w-full border border-gray-300 p-2 rounded"
        required  // Make the dropdown required

    >
          <option value={0}>Select Brand</option>
          {brands.map((brand) => (
            <option key={brand.brand_id} value={brand.brand_id}>
              {brand.brand_name}
            </option>
          ))}
        </select>
      </label>
      <div className="flex flex-wrap -mx-2">

      <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
          <label className="block mb-2 text-gray-700">
        Bank Name:
        <input type="text" value={bankName} onChange={(e) => setBankName(e.target.value)}
                  className="block w-full mt-1 p-2 border rounded-md"
                  required 
         />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">

          <label className="block mb-2 text-gray-700">
        Bank Account Number:
        <input type="number" value={bankAccountNumber} onChange={(e) => setBankAccountNumber(e.target.value)}          className="block w-full mt-1 p-2 border rounded-md"
              required 
     />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">

          <label className="block mb-2 text-gray-700">
        Swift Code:
        <input type="text" value={swiftCode} onChange={(e) => setSwiftCode(e.target.value)}          className="block w-full mt-1 p-2 border rounded-md"
              required 
     />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">

          <label className="block mb-2 text-gray-700">
        Routing Number:
        <input type="number" value={routingNumber} onChange={(e) => setRoutingNumber(e.target.value)} 
                  className="block w-full mt-1 p-2 border rounded-md"
                  required 
        />
        </label>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">

          <label className="block mb-2 text-gray-700">
        Bank Address:
        <input type="text" value={bankAddress} onChange={(e) => setBankAddress(e.target.value)}           className="block w-full mt-1 p-2 border rounded-md"
              required 
    />
        </label>
        </div>
        </div>
        <div className="w-full mb-4 flex justify-center">

<button
      style={{ borderRadius: '20px', padding: '10px', alignItems: 'center',justifyContent:'center' }} // Added styles
      className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
  type="submit"
>
  Submit
</button>
      <button type="button" onClick={InvoicDetails} style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }} // Added styles
                    className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Bank Details
                </button>
                </div>
    </form>
    </>
  );
}

export default InsertBankForm;
