import React, { useState, useEffect } from 'react';

const Fileuploaddetails = () => {
  const [vendorData, setVendorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [editingItem, setEditingItem] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const apiUrl = process.env.REACT_APP_BASE_URL;
  
  const itemsPerPage = 50;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // Fetch token from localStorage

      let url = `${apiUrl}Get_inventory?flag=1`;
      if (searchQuery) {
        url += `&ICCID=${searchQuery}`;
      }

      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in headers
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const responseData = await response.json();

      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      if (Array.isArray(data)) {
        setVendorData(data);
      } else {
        console.error('Parsed data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = vendorData.slice(indexOfFirstItem, indexOfLastItem);

  const renderTableRows = () => {
    return currentItems.map((vendor, index) => (
      <tr key={index} className="border-b">
        <td className="py-2">{vendor.Id}</td>
        <td className="py-2">{vendor.brand_name}</td>
        <td className="py-2">{vendor.ICCID}</td>
        <td className="py-2">{vendor.MSISDN}</td>
        <td className="py-2">{vendor.Status}</td>
        <td className="py-2">{vendor.vendorfirstname}</td>
        <td className="py-2">{vendor.plan_name}</td>
        <td className="py-2">{vendor.client}</td>
        <td className='py-2'>
          <button onClick={() => handleEdit(vendor)}>Edit</button>
        </td>
      </tr>
    ));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = () => {
    fetchData();
  };

  const handleEdit = (vendor) => {
    setEditingItem(vendor);
    // Open modal here
  };

  const handleCloseModal = () => {
    setEditingItem(null);
    // Close modal here
  };

  const handleStatusUpdate = async (status) => {
    try {
      const token = localStorage.getItem('authToken'); // Fetch token from localStorage

      const response = await fetch('https://demo.claytelecom.com/api/Authentication/update_inventory_status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include token in headers
        },
        body: JSON.stringify({
          flag: 1,
          id: editingItem.Id,
          status: status
        })
      });
      
      // Check if the status update was successful
      if (response.ok) {
        // Update the status in the local data
        const updatedData = vendorData.map(item => {
          if (item.Id === editingItem.Id) {
            return { ...item, Status: status };
          }
          return item;
        });
        setVendorData(updatedData);
        // Close modal after successful update
        handleCloseModal();
        // Set success message
        setSuccessMessage('Status updated successfully!');
        // Clear success message after a few seconds
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      } else {
        console.error('Status update failed');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
      <h1 className="text-3xl font-bold mb-4">Inventory Management</h1>
      <div className="flex items-center justify-between mt-4">
        <input
          type='search'
          className="p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-48"
          placeholder="Search by ICCID"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button
          onClick={handleSearch}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Search
        </button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Download PDF</button>
      </div>
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th> ID</th>
            <th className="py-2">Brand Name</th>
            <th className="py-2">ICCID</th>
            <th className="py-2">MSISDN</th>
            <th className="py-2">Status</th>
            <th className="py-2">vendorfirstname</th>
            <th className="py-2">Plan Name</th>
            <th className="py-2">Client Name</th>
            <th className="py-2">Action</th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Prev
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={indexOfLastItem >= vendorData.length}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Next
        </button>
      </div>

{/* Modal for editing status */}
{editingItem && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg">
          <h2 className="text-xl font-bold mb-4">Edit Status</h2>
      <p>ID: {editingItem.Id}</p>
      <br></br>
      <br></br>
      <select onChange={(e) => handleStatusUpdate(e.target.value)} value={editingItem.Status}             className="w-full p-2 border border-gray-300 rounded"
>
      <option>Select option</option>
        <option value="2">Inactive</option>
        <option value="3">Stolen</option>
      </select>
      <br></br>
      <br></br>

      <div className="w-full mb-4  flex justify-center">

      <button onClick={handleCloseModal}>Cancel</button> {/* Button to close modal */}
      <button onClick={() => handleStatusUpdate(editingItem.Status)}>Submit</button> {/* Button to submit status update */}
    </div>
    </div>
  </div>
)}

      {/* Success message */}
      {successMessage && (
        <div className="success-message">{successMessage}</div>
      )}
    </div>
  );
};

export default Fileuploaddetails;
