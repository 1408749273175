import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Invoicedatapdf = () => {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [editedInvoice, setEditedInvoice] = useState(null);
  const [validationErrors, setValidationErrors] = useState(null);
  const [salesDetails, setSalesDetails] = useState(null);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const response = await fetch(`${apiUrl}Get_invoice_details`);
        const data = await response.json();
        const jsonDataArray = JSON.parse(data.jsondata);
        setInvoices(jsonDataArray);
      } catch (error) {
        console.error('Error fetching invoice data:', error);
      }
    };

    const fetchSalesData = async () => {
      try {
        const response = await fetch(`${apiUrl}Get_sales_details?flag=1`);
        const data = await response.json();
        if (data.isSuccess) {
          const salesData = JSON.parse(data.jsondata)[0];
          setSalesDetails(salesData);
        } else {
          console.error('API request for sales details failed:', data.message);
        }
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };

    fetchInvoiceData();
    fetchSalesData();
  }, []);

  const handleEditClick = (invoice) => {
    setEditedInvoice({ ...invoice });
    setSelectedInvoice(invoice);
  };

  const handleFormClose = async () => {
    try {
      const updateResponse = await axios.post(
        `${apiUrl}update_sales_details`,
        {
          flag: 1,
          id: editedInvoice.Id,
          customer_name: editedInvoice.customer_name,
          invoiceno: editedInvoice.invoiceno,
          amount: editedInvoice.amount,
          status: 1,
        }
      );

      console.log('Update response:', updateResponse.data);

      const updatedData = await fetch(`${apiUrl}Get_invoice_details`);
      const updatedJsonData = await updatedData.json();
      const updatedJsonDataArray = JSON.parse(updatedJsonData.jsondata);

      setInvoices(updatedJsonDataArray);
      setValidationErrors(null);
    } catch (error) {
      console.error('Error updating sales details:', error);
      if (error.response) {
        console.log('Server response:', error.response.data);
        if (error.response.data.errors) {
          setValidationErrors(error.response.data.errors);
        }
      }
    } finally {
      setSelectedInvoice(null);
      setEditedInvoice(null);
    }
  };

  const handleInputChange = (e) => {
    setEditedInvoice({ ...editedInvoice, [e.target.name]: e.target.value });
  };

  return (
    <div>
                <h1 className="text-3xl font-bold mb-4">Invoice Details</h1>
      <div className="container mx-auto mt-8 p-4 bg-zinc-200	 rounded-lg">
        <div className="flex flex-wrap -mx-2">

          {salesDetails ? (
            <>
              <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
                <label className="block mb-2 text-gray-700">Total Outstanding Receivable</label>
                <span>{salesDetails.total_outstanding_receivable}</span>


              </div>

              <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
                <label className="block mb-2 text-gray-700">Due in 30 Days:</label>
                <span> {salesDetails.due_in_30_days}</span>

              </div>
              <div className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
                <label className="block mb-2 text-gray-700">Overdue More Than 30 Days: </label>
                <span>{salesDetails.overdue_more_than_30_days}</span>

              </div>

            </>

          ) : (
            <p>Loading sales details...</p>
          )}
        </div>
      </div>
      <table>
        <thead>
          <tr>
          <th>ID</th>
            <th>Customer Name</th>
            <th>Order Number</th>
            <th>Amount</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(invoices) && invoices.length > 0 ? (
            invoices.map((invoice) => (
              <tr key={invoice.Id}>
                                <td>{invoice.Id}</td>
                <td>{invoice.clientname}</td>
                <td>{invoice.order_no}</td>
                <td>{invoice.amount}</td>
                <td>
                  <button onClick={() => handleEditClick(invoice)}>Edit</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No invoices available</td>
            </tr>
          )}
        </tbody>
      </table>

      {selectedInvoice && (
        <div>
                <h1 className="text-3xl font-bold mb-4">Invoice Details</h1>
          <form>
            <label>Customer Name:</label>
            <input type="text" name="clientname" value={editedInvoice.clientname} onChange={handleInputChange} />


            <label>Order Number:</label>
            <input type="text" name="order_no" value={editedInvoice.order_no} onChange={handleInputChange} />

            <label>Amount:</label>
            <input type="text" name="amount" value={editedInvoice.amount} onChange={handleInputChange} />

            {validationErrors && (
              <div style={{ color: 'red' }}>
                {Object.entries(validationErrors).map(([key, value]) => (
                  <p key={key}>{`${key}: ${value}`}</p>
                ))}
              </div>
            )}


            
<div className="w-full mb-4 flex justify-center">
                <button
                    style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
                    className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="button"
                    onClick={handleFormClose}>Save Changes                </button>
                <button
                    type="button"
                    style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }} // Added styles
                    className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={() => setSelectedInvoice(null)}>
                    Invoicedetails
                </button>
                
            </div>
          </form>
        </div>
      )}

    </div>
  );
};

export default Invoicedatapdf;
