import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart1 from '../images/Login.jpg';
import login from '../images/login-3.webp';

const CreateUser = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  const [formData, setFormData] = useState({
    flag: 0,
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    role: '1',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.firstname ||
      !formData.lastname ||
      !formData.email ||
      !formData.password
    ) {
      alert('All fields are required');
      return;
    }

    try {
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Authentication token is not available');
      }
      const response = await fetch(
        `${apiUrl}CreateUser`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the request headers
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log('User created successfully');
        alert('User created successfully');
        navigate('/CreatePlan');
      } else {
        console.error('Error creating user:', response.statusText);
        alert('Error creating user. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error.message);
      alert('Error creating user. Please try again.');
    }
  };

  return (
    <>

<div className="flex items-center justify-center h-screen">

      <div className="bg-white px-4 py-6 rounded shadow-md text-black md:w-1/2 lg:w-1/3">
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px', minWidth: '100px' }}>
  <img src={login} alt="Hello" style={{ maxWidth: '150px', maxHeight: '150px' }} />
</div>

      <div>
          <label htmlFor="role" className="block text-sm font-semibold mb-2">
            Role
          </label>
          <select
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          >            <option value="1">Select Type</option>
            <option value="4">Client</option>
            <option value="2">Vendor</option>
          </select>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-wrap mb-4">
            <div className="w-full mb-4">
              <label htmlFor="firstname" className="block text-sm font-semibold mb-2">
                First Name
              </label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                required
              />
            </div>
            <div className="w-full mb-4">
              <label htmlFor="lastname" className="block text-sm font-semibold mb-2">
                Last Name
              </label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                required
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-semibold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-semibold mb-2">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="w-full mb-4 flex justify-center">
            <button
              type="submit"
              style={{
                borderRadius: '20px',
                padding: '20px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className="w-medium bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Create User
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
};

export default CreateUser;
