import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart1 from '../images/Login.jpg';

const Signup = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    flag: 0, // Update flag to 0
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    role: '1', // Default role is Admin
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (
      !formData.firstname ||
      !formData.lastname ||
      !formData.email ||
      !formData.password
    ) {
      alert('All fields are required');
      return;
    }

    try {
      const response = await fetch(
        'https://demo.claytelecom.com/api/Authentication/CreateUser',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Handle success, you might want to navigate to another page
        console.log('User created successfully');
        navigate('/');
      } else {
        // Handle error, show an error message or log the response
        console.error('Error creating user:', response.statusText);
      }
    } catch (error) {
      // Handle network errors or other exceptions
      console.error('Error:', error.message);
    }
  };

  const Loginpage = () => {
    navigate('/');
  };

  return (
    <div className="signup-login-form-container">
      <div className="image-container">
        <img src={Chart1} alt="Hello" />
      </div>
      <div className="form-container">
        <div>
          <label htmlFor="role" className="block text-sm font-semibold mb-2">
            Role
          </label>
          <select
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          >
            <option value="1">Admin</option>
            <option value="4">Client</option>
            <option value="2">Vendor</option>
          </select>
        </div>
        <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="firstname" 
       className="block text-sm font-semibold mb-2">
            First Name
          </label>
          <input
            type="text"
            id="firstname"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="lastname" className="block text-sm font-semibold mb-2">
            Last Name
          </label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-semibold mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-semibold mb-2">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
                  <div className="w-full mb-4 flex justify-center">
            <button
              type="submit"
              style={{
                borderRadius: '20px',
                padding: '20px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className="w-medium bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Create User
            </button>

            <button
              type="button"
              onClick={Loginpage}
              style={{
                borderRadius: '20px',
                padding: '20px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className="w-medium bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none"
            >
              Login User
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
