import React, { useState, useEffect } from 'react';
import '../css/Vendordetails.css';

const Productdetails = () => {
  const [productData, setProductData] = useState([]);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // Get token from localStorage
      if (!token) {
        console.error('No token found in localStorage');
        return;
      }

      const response = await fetch(`${apiUrl}Getproductdetail?flag=1`, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in headers
        },
      });

      const responseData = await response.json();
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      if (Array.isArray(data)) {
        setProductData(data);
      } else {
        console.error('Parsed data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productData.slice(indexOfFirstItem, indexOfLastItem);

  const renderTableRows = () => {
    return currentItems.map((product, index) => (
      <tr key={index}>
        <td>{product.c_id}</td>
        <td>{product.p_id}</td>
        <td>{product.name}</td>
        <td>{product.lastname}</td>
        <td>{product.phone_no}</td>
        <td>{product.phonenumber}</td>
        <td>{product.emailid}</td>
        <td>{product.company_name}</td>
        <td>{product.zip_code}</td>
        <td>{product.website}</td>
        <td>{product.fax}</td>
        <td>{product.phonenumber}</td>
        <td>{product.contact_number}</td>
      </tr>
    ));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <h1>Product Details</h1>
      <table>
        <thead>
          <tr>
            <th>V_id</th>
            <th>P_id</th>
            <th>User Name</th>
            <th>Last Name</th>
            <th>Mobile Number</th>
            <th>Phoneno</th>
            <th>Email</th>
            <th>Company Name</th>
            <th>Zip Code</th>
            <th>Website</th>
            <th>Fax</th>
            <th>Phone Number</th>
            <th>Contact Number</th>
          </tr>
        </thead>
        <tbody>{renderTableRows()}</tbody>
      </table>

      <div className="pagination">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Prev
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={indexOfLastItem >= productData.length}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Productdetails;
