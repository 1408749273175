import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Logoinp from '../images/ClayLogo.png'

const Invoice = ({ selectedInvoice }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

    if (!selectedInvoice) {
      return null;
    }

   
  const renderItems = () => {
    return selectedInvoice.items.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.name}</td>
        <td>{item.description}</td>
        <td>{item.unitPrice}</td>
        <td>{item.discount}</td>
        <td>{item.total}</td>
      </tr>
    ));
  };
  return (
    <>
    <div ref={componentRef}>
    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '30px' }}>
        <b>
          <span style={{ fontSize: '15.0pt', fontFamily: 'Arial, sans-serif', color: '#424453' }}>Clay Techsystems LLP</span>
        </b>
        <b>
          <span style={{ fontSize: '18.0pt', fontFamily: 'Arial, sans-serif' }}>INVOICE:{selectedInvoice.Due_date}</span>
        </b>
      </div>

      <br />

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <div style={{ marginLeft: 'auto' }}> {/* Aligns to the right */}
          <table className="first-table">
            <tr>
              <th>Invoice Number</th>
              <td style={{ paddingRight: '30px' }}>{selectedInvoice.invoiceno}</td>
            </tr>
            <tr>
              <th>Invoice Date</th>
              <td>{selectedInvoice.invoice_date}</td>
            </tr>
            <tr>
              <th>Billing Month</th>
              <td>{selectedInvoice.billing_date}</td>
            </tr>
          </table>
        </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <b>
          <span style={{ fontSize: '10.0pt', fontFamily: 'Arial, sans-serif' }}>Bill To: Skyroam</span>
        </b>
      </div>
      <br />

    

      <table className="second-table">
        <tr style={{ backgroundColor: '#d5d6df' }}>
          <th>S.No</th>
          <th>Product Description</th>
          <th>Qty.</th>
          <th>Unit Price</th>
          <th>Total</th>
        </tr>
        <tr>
          <td style={{ height: '50px' }}>1.</td>
          <td>{selectedInvoice.item}</td>
          <td>{selectedInvoice.qty}</td>
          <td>USD {selectedInvoice.Unit_price}</td>
          <td>USD  {selectedInvoice.amount}</td>
        </tr>
      </table>

      <br />

      <table className="third-table">
        <tr>
          <td style={{ width: '80%', textAlign: 'right' }}>Bank Fee</td>
          <td style={{ textAlign: 'center' }}>USD {selectedInvoice.bank_fee} </td>
        </tr>
        <tr>
          <td style={{ width: '80%', textAlign: 'right' }}>Subtotal</td>
          <td style={{ textAlign: 'center' }}>USD  {selectedInvoice.amount}</td>
        </tr>
        <tr>
          <td style={{ textAlign: 'right' }}>Currency Exchange Rate</td>
          <td style={{ textAlign: 'center' }}>1.00000 USD</td>
        </tr>
        <tr>
          <th style={{ textAlign: 'right' }}>Total Due</th>
          <th style={{ backgroundColor: '#ffff99', textAlign: 'center' }}>US$  {selectedInvoice.amount}</th>
        </tr>
      </table>


      <br /><br />

      <div style={{ fontSize: '10px', textDecoration: 'underline', textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>Supply Meant for Export under Bond/LUT without payment of IGST</div>


      <table className="fourth-table">
        <tr>
          <th style={{ border: "2px solid blue" }}>SEND PAYMENT TO:</th>
          <th style={{ border: "2px solid blue" }}>Note:</th>
        </tr>
        <tr>
          <td style={{ width: '50%' , border: "2px solid blue"}}>
            
          <p><b>Company Name:</b> {selectedInvoice.selectedClient ? selectedInvoice.selectedClient.company_name : 'N/A'}</p>
            <p><b>Company Bank A/c No:</b> {selectedInvoice.selectedClient ? selectedInvoice.selectedClient.bank_account_number : 'N/A'}</p>
            <p><b>Company Bank Name:</b> {selectedInvoice.selectedClient ? selectedInvoice.selectedClient.bank_name : 'N/A'}</p>
            <p><b>Bank Address:</b> Union Bank of India SSI Okhla Branch, Okhla Industrial Area, Phase-I, New Delhi- 110020</p>
            <p><b>Swift Address/Code:</b></p>
            <p><b>Intermediary Bank Name for USD:</b> Citi Bank N.A.</p>
            <p><b>Intermediary Bank Swift address/Code:</b> CITIUS33</p>
          </td>
          <td style={{ fontSize: '11.0pt', paddingLeft: '15px', border: "2px solid blue" }}>“Please make the payment 08<sup>th</sup> Jan 2024”</td>
        </tr>
      </table>

      <br />

      <div style={{ border: 'solid #dbdbdb 1.5pt', padding: '5px', textAlign: 'center', fontSize: '12.0pt', fontFamily: 'Arial, sans-serif' }}>
        <b>THANK YOU FOR YOUR BUSINESS!!</b>
      </div>

      <br />
      <div className="footer" style={{ textAlign: 'center' }}>
        <p><b><u>TERRAH HUB LLP</u></b></p>
        {/* <p><b>D-158 B, Okhla Industrial Area, Phase - 1, New Delhi - 110020, India.</b></p>
        <p><b>Tel: Phone: +91-11-66470000/66470001, Fax:-91-11-66470099</b></p>
        <p>GSTIN: 07AAPFT6887P1ZU, LUT No: - Applied for, PAN No: - AAPFT6887P</p>
        <p><b>LLP Identification Number AAR-0459</b></p> */}
      </div>
    </div>
      <div className="w-full mb-4 flex justify-center">
                <button
                    style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }}
                    className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                    onClick={handlePrint}>Download  </button>

            </div>
     </>
  );
};

export default Invoice;
