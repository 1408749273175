import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Fileupload = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  const [brands, setBrands] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');
  const [csvFile, setCsvFile] = useState(null);

  // Retrieve the token from localStorage
  const token = localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      fetchBrands();
      fetchVendors();
    } else {
      alert('Token is missing. Please log in again.');
      // Optionally redirect to login page
      // window.location.href = '/login';
    }
  }, [token]);

  const handleNavigateToDetails = () => {
    navigate('/Fileuploaddetails');
  };

  const fetchBrands = async () => {
    try {
      const response = await axios.get(`${apiUrl}Get_brand`, {
        headers: {
          Authorization: `Bearer ${token}`, // Attach the token to the headers
        },
      });

      const responseData = response.data;

      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      if (Array.isArray(data)) {
        setBrands(data.map((brand) => ({ id: brand.brand_id, name: brand.brand_name })));
      } else {
        console.error('Parsed brand data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching brand data:', error);
    }
  };

  const fetchVendors = async () => {
    try {
      const response = await axios.get(`${apiUrl}Getvendordetail?flag=1`, {
        headers: {
          Authorization: `Bearer ${token}`, // Attach the token to the headers
        },
      });

      const responseData = response.data;
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      if (Array.isArray(data)) {
        setVendors(data.map((vendor) => ({ value: vendor.v_id, label: vendor.name })));
      } else {
        console.error('Parsed vendor data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };

  const handleBrandChange = (e) => {
    const selectedBrandId = e.target.value;
    setSelectedBrand(selectedBrandId);
    // Additional logic if needed
  };

  const handleVendorChange = (e) => {
    const selectedVendorId = e.target.value;
    setSelectedVendor(selectedVendorId);
    console.log('vendor', selectedVendorId);
    // Additional logic if needed
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Check if the selected file is a CSV file
    if (file && file.type === 'text/csv') {
      setCsvFile(file);
    } else {
      console.error('Please select a valid CSV file.');
      setCsvFile(null);
    }
  };

  const downloadSampleCSV = () => {
    // Programmatically create an anchor element
    const link = document.createElement('a');
    // Set the href to the download link
    link.href = 'https://drive.google.com/uc?id=1FR8tpj5lsjAYXKhD0nGC2yjpRIHyjING&export=download';
    // Set the download attribute to specify the filename
    link.download = 'sample.csv';
    // Append the anchor element to the body
    document.body.appendChild(link);
    // Trigger the click event to start the download
    link.click();
    // Remove the anchor element from the body
    document.body.removeChild(link);
  };

  const handleUpload = async () => {
    try {
      if (!selectedBrand) {
        console.error('Please select a brand.');
        return;
      }

      if (!selectedVendor) {
        console.error('Please select a vendor.');
        return;
      }

      if (!csvFile) {
        console.error('Please select a CSV file.');
        return;
      }

      const formData = new FormData();
      formData.append('brandid', selectedBrand);
      formData.append('vendorid', selectedVendor);
      formData.append('file', csvFile);

      const response = await axios.post(
        `${apiUrl}ImportCsv?brandid=` + selectedBrand + '&vendorid=' + selectedVendor,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`, // Attach the token to the headers
          },
        }
      );

      console.log('CSV upload successful:', response.data);

      // Show alert on successful upload
      alert('CSV upload successful!');
    } catch (error) {
      console.error('Error uploading CSV:', error);
      // Show alert on error
      alert('Error uploading CSV. Please try again.');
    }
  };

  return (
    <>
      <form>
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-2">Select Brand:</label>
          <select
            value={selectedBrand}
            onChange={handleBrandChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="0">Select a Brand</option>
            {brands.map((brand) => (
              <option key={brand.id} value={brand.id}>
                {brand.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold mb-2">Select Vendor:</label>
          <select
            value={selectedVendor}
            onChange={handleVendorChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="0">Select a Vendor</option>
            {vendors.map((vendor) => (
              <option key={vendor.value} value={vendor.value}>
                {vendor.label}
              </option>
            ))}
          </select>
        </div>

        <label className="block text-sm font-semibold mb-2">Upload CSV file:</label>
        <input
          type="file"
          onChange={handleFileChange}
          className="w-full p-2 border border-gray-300 rounded mb-4"
        />

        <div className="w-full mb-4 flex justify-center">
          <button
            style={{ borderRadius: '20px', padding: '20px', alignItems: 'center', justifyContent: 'center' }}
            onClick={handleUpload}
            className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
            type="button"
          >
            Submit
          </button>
          <button
            type="button"
            onClick={handleNavigateToDetails}
            style={{ borderRadius: '20px', padding: '20px', alignItems: 'center', justifyContent: 'center' }}
            className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          >
            Upload Details
          </button>
          <button
            type="button"
            onClick={downloadSampleCSV}
            style={{ borderRadius: '20px', padding: '20px', alignItems: 'center', justifyContent: 'center' }}
            className="block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          >
            Sample CSV
          </button>
        </div>
      </form>
    </>
  );
};

export default Fileupload;
