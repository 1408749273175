import React, { useState, useEffect } from 'react';

const Getallplans = () => {
  const [planData, setPlanData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [editingPlan, setEditingPlan] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('token'); // Get token from localStorage

  const itemsPerPage = 5;

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}Get_all_Plans`, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include token in headers
        },
      });
      const responseData = await response.json();

      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      if (Array.isArray(data)) {
        setPlanData(data);
      } else {
        console.error('Parsed data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = planData.filter(plan =>
    plan.plan_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleEdit = (plan) => {
    setEditingPlan(plan);
  };

  const handleSave = async (updatedPlan) => {
    try {
      const response = await fetch(`${apiUrl}Authentication/editplan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include token in headers
        },
        body: JSON.stringify(updatedPlan),
      });
      if (response.ok) {
        const updatedData = planData.map(p =>
          p.plan_id === updatedPlan.plan_id ? updatedPlan : p
        );
        setPlanData(updatedData);
        setEditingPlan(null);
      } else {
        console.error('Failed to edit plan details:', response.statusText);
      }
    } catch (error) {
      console.error('Error editing plan details:', error);
    }
  };

  const renderTableRows = () => {
    return filteredData.map((plan, index) => (
      <tr key={index} className="border-b">
        <td className="py-5">{plan.brandname}</td>
        <td className="py-5">{plan.vendorname}</td>
        <td className="py-5">{plan.plan_name}</td>
        <td className="py-5">{plan.currency}</td>
        <td className="py-5">{plan.buying_price}</td>
        <td className="py-5">{plan.tax}</td>
        <td className="py-5">{plan.total_price}</td>
        <td className="py-5">
          <button onClick={() => handleEdit(plan)}>Edit</button>
        </td>
      </tr>
    ));
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
      <h1 className="text-3xl font-bold mb-4">Created Plan Details</h1>
      <div className="flex items-center justify-between mt-4">
        <input
          type='search'
          className="p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-48"
          placeholder="Search By Plan Name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Download PDF</button>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr>
              <th className="py-5">Brand Name</th>
              <th className="py-5">Vendor Name</th>
              <th className="py-5">Plan Name</th>
              <th className="py-5">Currency</th>
              <th className="py-5">Buying Price</th>
              <th className="py-5">Tax</th>
              <th className="py-5">Total Price</th>
              <th className="py-5">Action</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>

      {editingPlan && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Edit Plan Details</h2>
            <form onSubmit={(e) => {
              e.preventDefault();
              handleSave(editingPlan);
            }}>
              <div className="mb-4">
                <label htmlFor="planName" className="block text-sm font-medium text-gray-700">Plan Name</label>
                <input
                  type="text"
                  id="planName"
                  name="planName"
                  value={editingPlan.plan_name}
                  onChange={(e) => setEditingPlan({ ...editingPlan, plan_name: e.target.value })}
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="currency" className="block text-sm font-medium text-gray-700">Currency</label>
                <input
                  type="text"
                  id="currency"
                  name="currency"
                  value={editingPlan.currency}
                  onChange={(e) => setEditingPlan({ ...editingPlan, currency: e.target.value })}
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="buyingPrice" className="block text-sm font-medium text-gray-700">Buying Price</label>
                <input
                  type="text"
                  id="buyingPrice"
                  name="buyingPrice"
                  value={editingPlan.buying_price}
                  onChange={(e) => setEditingPlan({ ...editingPlan, buying_price: e.target.value })}
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="tax" className="block text-sm font-medium text-gray-700">Tax</label>
                <input
                  type="text"
                  id="tax"
                  name="tax"
                  value={editingPlan.tax}
                  onChange={(e) => setEditingPlan({ ...editingPlan, tax: e.target.value })}
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="totalPrice" className="block text-sm font-medium text-gray-700">Total Price</label>
                <input
                  type="text"
                  id="totalPrice"
                  name="totalPrice"
                  value={editingPlan.total_price}
                  onChange={(e) => setEditingPlan({ ...editingPlan, total_price: e.target.value })}
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="flex justify-between">
                <button type="button" onClick={() => setEditingPlan(null)} className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">Cancel</button>
                <button type="submit" className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600">Save</button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Prev
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={indexOfLastItem >= filteredData.length}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Getallplans;
