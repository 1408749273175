import React, { useState, useEffect, useCallback } from 'react';
import * as XLSX from 'xlsx';

function AssignInventoryList() {
  const [assignInventoryList, setAssignInventoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('token'); // Retrieve the token from session storage

  const itemsPerPage = 100;

  useEffect(() => {
    if (token) {
      fetchAssignInventoryList();
    } else {
      console.error('No token found in session storage.');
    }
  }, [pageNumber, token]);

  const fetchAssignInventoryList = () => {
    setLoading(true);
    fetch(
      `${apiUrl}Get_Assigninvertry_list_notbilled?flag=1&page=${pageNumber}&search=${searchQuery}`,
      {
        headers: {
          Authorization: `Bearer ${token}`, // Add Authorization header
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const parsedData = JSON.parse(data.jsondata);
        setAssignInventoryList((prevItems) => [...prevItems, ...parsedData]);
        setLoading(false);
      })
      .catch((error) =>
        console.error('Error fetching assign inventory list:', error)
      );
  };

  const loadMoreItems = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(assignInventoryList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'AssignInventoryList');
    XLSX.writeFile(workbook, 'assignInventoryList.xlsx');
  };

  // Debounce the search input to reduce the number of API calls
  const handleSearch = useCallback(
    (event) => {
      const { value } = event.target;
      setSearchQuery(value);
      setPageNumber(1); // Reset page number when search query changes
      setAssignInventoryList([]); // Clear the current list for new search results
    },
    [setSearchQuery, setPageNumber, setAssignInventoryList]
  );

  return (
    <div>
      <h1>Assign Inventory List</h1>
      <div className="search-wrapper">
        <label htmlFor="search-form">
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="search-input"
            placeholder="Search for..."
            value={searchQuery}
            onChange={handleSearch}
          />
          <span className="sr-only">Search countries here</span>
        </label>
      </div>
      <button onClick={downloadExcel}>Download Excel</button>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>ICCID</th>
                <th>Brand Name</th>
                <th>Vendor First Name</th>
                <th>Client First Name</th>
                <th>Client Last Name</th>
                <th>Plan Name</th>
                <th>Status</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {assignInventoryList.map((item) => (
                <tr key={item.ICCID}>
                  <td>{item.ICCID}</td>
                  <td>{item.brand_name}</td>
                  <td>{item.vendorfirst}</td>
                  <td>{item.clientfirst}</td>
                  <td>{item.clientlast}</td>
                  <td>{item.plan_name}</td>
                  <td>{item.Status}</td>
                  <td>
                    {' '}
                    <button>Edit {item.Edit}</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            {assignInventoryList.length < pageNumber * itemsPerPage ? null : (
              <button onClick={loadMoreItems}>Load More</button>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default AssignInventoryList;
