import React, { useState, useEffect } from 'react';

const Vendordetails = () => {
  const [vendorData, setVendorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [editingVendor, setEditingVendor] = useState(null); // State to hold the vendor being edited
  const [searchQuery, setSearchQuery] = useState(''); // State to hold the search query
  const [detailsData, setDetailsData] = useState(null);

  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const itemsPerPage = 5;

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${apiUrl}Getvendordetail?flag=1`);
      const responseData = await response.json();
  
      // Check if jsondata is present and parse it into an array
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];
  
      // Filter the data based on the search query for vendor's name
      const filteredData = data.filter(vendor =>
        vendor.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      // Check if data is an array before setting it
      if (Array.isArray(filteredData)) {
        setVendorData(filteredData);
      } else {
        console.error('Parsed data is not an array:', filteredData);
      }
  
      console.log('Response data:', await response.text()); // Log the response text
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = vendorData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleEdit = (vendor) => {
    // Set the editing vendor
    setEditingVendor(vendor);
  };

  const handleSave = async (updatedVendor) => {
    try {
      // Make API call to edit vendor details
      const response = await fetch(`${apiUrl}editvendor`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedVendor)
      });
      if (response.ok) {
        // Update local vendor data with the edited vendor
        const updatedData = vendorData.map(v =>
          v.vid === updatedVendor.vid ? updatedVendor : v
        );
        setVendorData(updatedData);
        setEditingVendor(null); // Reset editing state
      } else {
        console.error('Failed to edit vendor details:', response.statusText);
      }
    } catch (error) {
      console.error('Error editing vendor details:', error);
    }
  };

  const handleShowDetails = async (vendor) => {
    try {
      const response = await fetch(`https://demo.claytelecom.com/api/Authentication/get_iccid_count?flag=1&v_id=${vendor.v_id}`);
      const responseData = await response.json();
      const parsedData = JSON.parse(responseData.jsondata)[0]; // Assuming there is only one object in the array
      setDetailsData(parsedData);
    } catch (error) {
      console.error('Error fetching details:', error);
    }
  };
  
  const renderTableRows = () => {
    return currentItems.map((vendor, index) => (
      <tr key={index} className="border-b">
        <td className="py-2">{vendor.v_id}</td>
        <td className="py-2">{vendor.name}</td>
        <td className="py-2">{vendor.lastname}</td>
        <td className="py-2">{vendor.phone_no}</td>
        <td className="py-2">{vendor.company_name}</td>
        <td className="py-2">{vendor.emailid}</td>
        <td className="py-2">{vendor.zip_code}</td>
        <td className="py-2">{vendor.website}</td>
        <td className="py-2">{vendor.fax}</td>
        <td className="py-2">{vendor.contact_number}</td>

        <td className="py-2">
          <button onClick={() => handleEdit(vendor)}>Edit</button>
        </td>
        <td className="py-2">
        <button onClick={() => handleShowDetails(vendor)}>Details</button>
      </td>
      </tr>
    ));
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg overflow-x-auto">
      <h1 className="text-3xl font-bold mb-4">Vendor Details</h1>
      <div className="flex items-center justify-between mt-4">
        <input 
          type='search' 
          className="p-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 w-48" 
          placeholder="Search by Name..." 
          value={searchQuery} 
          onChange={handleSearch} 
        />
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Download PDF</button>
      </div>
      <div className="table-responsive">
        <table className="w-full table-auto border-collapse">
          <thead>
            <tr>
              <th className="py-2">V_id</th>
              <th className="py-2">User Name</th>
              <th className="py-2">Last Name</th>
              <th className="py-2">Mobile Number</th>
              <th className="py-2">Company Name</th>
              <th className="py-2">Email Id</th>
              <th className="py-2">Zip Code</th>
              <th className="py-2">Website</th>
              <th className="py-2">Fax</th>
              <th className="py-2">Country</th>
              <th className="py-2">Action</th>
              <th className="py-2">Details</th>

            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>

      {detailsData && (
  <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
    <div className="bg-white p-8 rounded-lg">
      <h2 className="text-xl font-bold mb-4">Vendor Details</h2>
      <p>Total Count: {detailsData.Total_Count}</p>
      <p>Total Available Count: {detailsData.Total_Available_Count}</p>
      <p>Total Inactive Count: {detailsData.Total_Inactive_Count}</p>
      <p>Total Lost Count: {detailsData.Total_Lost_Count}</p>
      <p>Total Stolen Count: {detailsData.Total_Stolen_Count}</p>

      <button onClick={() => setDetailsData(null)}>Close</button>
    </div>
  </div>
)}


      {/* Modal for editing */}
      {editingVendor && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Edit Vendor Details</h2>
            <form onSubmit={(e) => {
              e.preventDefault();
              handleSave(editingVendor);
            }}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={editingVendor.name}
                  onChange={(e) => setEditingVendor({ ...editingVendor, name: e.target.value })}
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phoneNo" className="block text-sm font-medium text-gray-700">Phone Number</label>
                <input
                  type="text"
                  id="phoneNo"
                  name="phoneNo"
                  value={editingVendor.phone_no}
                  onChange={(e) => setEditingVendor({ ...editingVendor, phone_no: e.target.value })}
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Company Name</label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={editingVendor.company_name}
                  onChange={(e) => setEditingVendor({ ...editingVendor, company_name: e.target.value })}
                  className="mt-1 p-2 block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div className="flex justify-between">
                <button type="button" onClick={() => setEditingVendor(null)} className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600">Cancel</button>
                <button type="submit" className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600">Save</button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Prev
        </button>
        <span>Page {currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={indexOfLastItem >= vendorData.length}
          className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Vendordetails;
