import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CreateProduct = () => {
  const navigate = useNavigate();

  const [vendorNames, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [clientFormData, setClientFormData] = useState({
    flag: 1,
    userName: '',
    lastname: '',
    email: '',
    mobileno: '',
    phoneno: '',
    website: '',
    companyname: '',
    contactno: '',
    zipcode: '',
    fax: '',
    phone_no: '',
    vid: '',
  });

  const handleNavigateToDetails = () => {
    navigate('/CreateProductdetails');
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  // Fetch vendors from API
  const fetchVendors = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage

      const response = await fetch('https://demo.claytelecom.com/api/Authentication/Get_clientdetails?flag=1', {
        headers: {
          'Authorization': `Bearer ${token}` // Include token in Authorization header
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch vendors');
      }

      const responseData = await response.json();

      // Check if jsondata is present and parse it into an array
      const data = responseData.jsondata ? JSON.parse(responseData.jsondata) : [];

      // Check if data is an array before setting it
      if (Array.isArray(data)) {
        setVendors(data.map((vendor) => ({ value: vendor.c_id, label: vendor.name })));
      } else {
        console.error('Parsed data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleVendorChange = (e) => {
    setSelectedVendor(e.target.value);
    setClientFormData({
      ...clientFormData,
      vid: e.target.value, // Set the vendor id in clientFormData when a vendor is selected
    });
  };

  const handleClientChange = (e) => {
    const { name, value } = e.target;
    setClientFormData({
      ...clientFormData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('authToken'); // Retrieve token from localStorage

      const response = await fetch('https://demo.claytelecom.com/api/Authentication/Createproducts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include token in Authorization header
        },
        body: JSON.stringify(clientFormData),
      });

      const responseData = await response.json();

      if (response.ok) {
        alert('Client created successfully. Thank you!');
        setClientFormData({
          flag: 1,
          userName: '',
          lastname: '',
          email: '',
          mobileno: '',
          phoneno: '',
          website: '',
          companyname: '',
          contactno: '',
          zipcode: '',
          fax: '',
          phone_no: '',
          vid: '',
        });
        navigate('/CreateProductdetails'); // Update the route accordingly
      } else {
        alert('Failed to create client. Please check your input and try again.');
        console.error('Failed to create client:', responseData);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="container mx-auto mt-8 p-4 bg-gray-100 rounded-lg">
        <h1 className="text-center text-3xl font-bold mb-6">Create Product</h1>

        {/* Dropdown for selecting a vendor */}
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-2">Select Client:</label>
          <select
            value={selectedVendor}
            onChange={handleVendorChange}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="" disabled>Select a Client</option>
            {vendorNames.map((vendor) => (
              <option key={vendor.value} value={vendor.value}>
                {vendor.label}
              </option>
            ))}
          </select>
        </div>

        {/* Form for creating a client */}
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              First Name:
              <input
                type="text"
                name="userName"
                value={clientFormData.userName}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              Last Name:
              <input
                type="text"
                name="lastname"
                value={clientFormData.lastname}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              Email:
              <input
                type="email"
                name="email"
                value={clientFormData.email}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              Website:
              <input
                type="url"
                name="website"
                value={clientFormData.website}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              Mobile Number:
              <input
                type="tel"
                name="mobileno"
                value={clientFormData.mobileno}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              Phone Number:
              <input
                type="tel"
                name="phoneno"
                value={clientFormData.phoneno}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              Company Name:
              <input
                type="text"
                name="companyname"
                value={clientFormData.companyname}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              Contact Number:
              <input
                type="tel"
                name="contactno"
                value={clientFormData.contactno}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              Zip Code:
              <input
                type="text"
                name="zipcode"
                value={clientFormData.zipcode}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              Fax:
              <input
                type="text"
                name="fax"
                value={clientFormData.fax}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 lg:w-2/4 px-4 mb-4">
            <label className="block mb-2 text-gray-700">
              Phone Number:
              <input
                type="tel"
                name="phone_no"
                value={clientFormData.phone_no}
                onChange={handleClientChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
          {/* Add more input fields here */}
        </div>

        {/* Additional details section */}
        {/* ... (similar to the vendor form) */}
        <div className="w-full mb-4 flex justify-center">
          <button
            style={{ borderRadius: '20px', padding: '20px', alignItems: 'center', justifyContent: 'center' }} // Added styles
            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4"
            type="submit"
          >
            Submit
          </button>
          <button
            type="button"
            onClick={handleNavigateToDetails}
            style={{ borderRadius: '20px', padding: '10px', alignItems: 'center', justifyContent: 'center' }} // Added styles
            className="btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Create Product Details
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateProduct;
