import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Sidebar from './Sidebar'
import Header from './Header'
import Details from './Details'

export default function Layout() {
  const location = useLocation();
  const isVendorToMasterbilling = location.pathname.startsWith('/Productdetails') || location.pathname.startsWith('/Productdetails')
  || location.pathname.startsWith('/Currencydetails')|| location.pathname.startsWith('/Currencydetails');

  

  return (
    <div className="bg-neutral-100 h-screen w-screen overflow-hidden flex flex-row">
      <Sidebar />
      <div className="flex flex-col flex-1">
        {isVendorToMasterbilling ? <Details /> : <Header />}
        <div className="flex-1 p-4 min-h-0 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
